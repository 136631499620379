import React from 'react';

import { Container, Bar } from './styles';

const ProgressBar = (props) => {
  return(    
    <Container>
        <Bar>
            <li className={props.progress >= 1 ? 'activated' : ''}><span></span></li>
            <li className={props.progress >= 2 ? 'activated' : ''}><span></span></li>
            <li className={props.progress >= 3 ? 'activated' : ''}><span></span></li>
            <li className={props.progress >= 4 ? 'activated' : ''}><span></span></li>
            <li className={props.progress >= 5 ? 'activated' : ''}><span></span></li>
            <li className={props.progress >= 6 ? 'activated' : ''}><span></span></li>
            <li className={props.progress >= 7 ? 'activated' : ''}></li>
        </Bar>
    </Container>
  );
}

export default ProgressBar;