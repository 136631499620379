export const questions = {
    "Tela 1": [
        {
            id: 1,
            title: "De forma geral, como você avalia o seu estado de saúde<label> e de seu(s) dependente(s)</label>?",
            html_for: "peg1",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "estado_saude",
            min_age_condition: 0,            
            answers: [
                {
                    id: 11,
                    value: "Ruim"
                },
                {
                    id: 12,
                    value: "Regular"
                },
                {
                    id: 13,
                    value: "Bom"
                }
            ]
        },
        {
            id: 2,
            title: "Você<label> ou seu(s) dependente(s)</label> tem alguma destas condições de saúde? Atenção: É possível selecionar mais de uma opção",
            html_for: "peg2",
            type: "checkbox",
            layout: "horizontal",
            show_question: true,
            column_name: "condicoes_saude",
            min_age_condition: 0,            
            answers: [
                {
                    id: 21,
                    value: "Diabetes Mellitus (taxa alta de açúcar no sangue)"
                },
                {
                    id: 22,
                    value: "Hipertensão Arterial (pressão alta)"
                },
                {
                    id: 23,
                    value: "Doenças cardiovasculares (Histórico de IAM (infarto), ICC (coração fraco), AVC/AVE (derrame), Arteroesclerose (presença de placa de gordura ou entupimento dos vasos))"
                },
                {
                    id: 24,
                    value: "Alteração do colesterol ou do triglicérides (taxa alta de gordura no sangue)"
                },
                {
                    id: 25,
                    value: "Doença Pulmonar Obstrutiva Crônica (DPOC) como Asma, Bronquite ou Enfisema Pulmonar"
                },
                {
                    id: 26,
                    value: "Alterações ortopédicas e/ou dores frequentes (musculares, articulares ou de coluna)"
                },
                {
                    id: 27,
                    value: "Alterações emocionais/psíquicas como depressão, ansiedade, transtorno bipolar, esquizofrenia, burnout, entre outras."
                },
                {
                    id: 28,
                    value: "Câncer - em tratamento ou em acompanhamento após finalização do tratamento (tratamento finalizado nos últimos 5 anos)"
                },
                {
                    id: 29,
                    value: "Insuficiência Renal Crônica"
                },
                {
                    id: 2.1,
                    value: "Não"
                }
            ]
        },
        {
            id: 3,
            title: "Qual a <strong style='color: #00cb00'>localização mais frequente</ strong> destas dores e/ou desconfortos?",
            html_for: "peg3",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "dores_desconfortos",
            question_father: 2,
            expected_answer: ['Alterações ortopédicas e/ou dores frequentes (musculares, articulares ou de coluna)'],
            min_age_condition: 0,            
            answers: [
                {
                    id: 31,
                    value: "Costas ou Pescoço"
                },
                {
                    id: 32,
                    value: "Pernas ou Braços"
                },
                {
                    id: 33,
                    value: "Articulações"
                },
                {
                    id: 34,
                    value: "Outros locais"
                }
            ]
        },
        {
            id: 4,
            title: "Qual é a <strong style='color: #00cb00'>frequência</ strong> destas dores e/ou desconfortos?",
            html_for: "peg4",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "frequencia_dores_desconfortos",
            question_father: 2,
            expected_answer: ['Alterações ortopédicas e/ou dores frequentes (musculares, articulares ou de coluna)'],
            min_age_condition: 0,            
            answers: [
                {
                    id: 41,
                    value: "Menos de 1x por mês"
                },
                {
                    id: 42,
                    value: "Menos de 1x por semana"
                },
                {
                    id: 43,
                    value: "De 1 a 2x por semana"
                },
                {
                    id: 44,
                    value: "Mais de 3x na semana"
                }
            ]
        },
        {
            id: 5,
            title: "Tem indicação médica para uso de algum medicamento de uso contínuo?<label> E seu(s) dependente(s)?</label>",
            html_for: "peg5",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "indicacao_medica_medicamento",
            min_age_condition: 0,            
            answers: [
                {
                    id: 51,
                    value: "Sim"
                },
                {
                    id: 52,
                    value: "Não"
                }
            ]
        },
        {
            id: 6,
            title: "Toma o medicamento conforme os dias e horários indicados pelo seu médico?",
            html_for: "peg6",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "diahora_tomando_medicamento",
            question_father: 5,
            expected_answer: ['Sim'],
            min_age_condition: 0,            
            answers: [
                {
                    id: 61,
                    value: "Sim"
                },
                {
                    id: 62,
                    value: "Sim, mas esqueço de tomar algumas vezes"
                },
                {
                    id: 63,
                    value: "Não, pois não possuo condições financeiras de aderir totalmente à prescrição médica"
                }
            ]
        },
        {
            id: 7,
            title: "Você<label> ou alguma dependente</label> está grávida?",
            html_for: "peg7",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "esta_gravida",
            genre_expected_answer: 'Feminino',
            min_age_condition: 10,            
            answers: [
                {
                    id: 71,
                    value: "Sim"
                },
                {
                    id: 72,
                    value: "Não"
                },
                {
                    id: 73,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 8,
            title: "Qual o mês e ano provável do parto?",
            html_for: "peg8",
            type: "month",
            show_question: false,
            column_name: "mes_ano_parto",
            genre_expected_answer: 'Feminino',
            min_age_condition: 10,   
            question_father: 7,
            expected_answer: ['Sim'],            
            answers: ['']
        },
        {
            id: 9,
            title: "Está realizando o acompanhamento pré-natal?",
            html_for: "peg9",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "acompanhamento_pre_natal",
            genre_expected_answer: 'Feminino',
            min_age_condition: 10,   
            question_father: 7,
            expected_answer: ['Sim'],            
            answers: [
                {
                    id: 91,
                    value: "Sim"
                },
                {
                    id: 92,
                    value: "Não"
                }
            ]
        },
        {
            id: 10,
            title: "Possui alguma condição que inspire cuidados durante a gestação? Atenção: é possível selecionar mais de uma opção.",
            html_for: "peg10",
            type: "checkbox",
            layout: "vertical",
            show_question: false,
            column_name: "cuidado_durante_gestacao",
            genre_expected_answer: 'Feminino',
            min_age_condition: 10,   
            question_father: 7,
            expected_answer: ['Sim'],            
            answers: [
                {
                    id: 101,
                    value: "Hipertensão (pressão alta) "
                },
                {
                    id: 102,
                    value: "Diabetes Gestacional (açúcar alto no sangue durante a gestação)"
                },
                {
                    id: 103,
                    value: "Gestação múltipla ou gemelar"
                },
                {
                    id: 104,
                    value: "Descolamento de placenta"
                },
                {
                    id: 105,
                    value: "Encurtamento de colo uterino"
                },
                {
                    id: 106,
                    value: "Alterações fetais"
                },
                {
                    id: 107,
                    value: "Sim, porém nenhuma destas"
                },
                {
                    id: 108,
                    value: "Não"
                },
                {
                    id: 109,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 11,
            title: "Nas últimas 2 semanas, você<label> ou algum dependente</label> têm apresentado pouco interesse ou pouco prazer em fazer as coisas simples do dia a dia?",
            html_for: "peg11",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "pouco_interesse",
            min_age_condition: 0,            
            answers: [
                {
                    id: 111,
                    value: "Nenhuma vez"
                },
                {
                    id: 112,
                    value: "Alguns dias"
                },
                {
                    id: 113,
                    value: "Mais da metade dos dias"
                },
                {
                    id: 114,
                    value: "Quase todos os dias"
                },
                {
                    id: 115,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 12,
            title: "Com que frequência você se sente<label> ou nota que seu(s) dependente(s) se sentem</label> irritado<label>s</label>(a<label>s</label>), nervoso<label>s</label>(a<label>s</label>) ou ansioso<label>s</label>(a<label>s</label>)? ",
            html_for: "peg12",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "sente_irritado",
            min_age_condition: 0,            
            answers: [
                {
                    id: 121,
                    value: "Nenhuma vez"
                },
                {
                    id: 122,
                    value: "Alguns dias"
                },
                {
                    id: 123,
                    value: "Mais da metade dos dias"
                },
                {
                    id: 124,
                    value: "Quase todos os dias"
                },
                {
                    id: 125,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 13,
            title: "Você acredita que a pandemia da COVID-19 lhe causou algum tipo de alteração emocional, mesmo que não esteja mais presente no momento?<label> E em seu(s) dependente(s)?</label>",
            html_for: "peg13",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "pouco_interesse_irritado_covid",
            min_age_condition: 0,        
            answers: [
                {
                    id: 131,
                    value: "Sim"
                },
                {
                    id: 132,
                    value: "Não"
                },
                {
                    id: 133,
                    value: "Não sei"
                }
            ]
        }
    ],
    "Tela 2": [
        {
            id: 15,
            title: "Algum de seus familiares de primeiro e segundo grau (pais, irmãos e avôs) já foram diagnosticados com alguma das patologias mencionadas?<label> E de seu(s) dependente(s)?</label> É possível selecionar mais de uma opção.",
            html_for: "peg15",
            type: "checkbox",
            layout: "horizontal",
            show_question: true,
            column_name: "historico_familiar",
            min_age_condition: 0,            
            answers: [
                {
                    id: 151,
                    value: "Diabetes Mellitus (taxa alta de açúcar no sangue)"
                },
                {
                    id: 152,
                    value: "Hipertensão Arterial (pressão alta)"
                },
                {
                    id: 153,
                    value: "Doenças cardiovasculares (Histórico de IAM (infarto), ICC (coração fraco), AVC/AVE (derrame), Arteroesclerose (presença de placa de gordura ou entupimento dos vasos))"
                },
                {
                    id: 154,
                    value: "Alteração do colesterol ou do triglicérides (taxa alta de gordura no sangue)"
                },
                {
                    id: 155,
                    value: "Doença Pulmonar Obstrutiva Crônica (DPOC) como Asma, Bronquite ou Enfisema Pulmonar"
                },
                {
                    id: 156,
                    value: "Alterações emocionais/psíquicas como depressão, ansiedade, transtorno bipolar, esquizofrenia, burnout, entre outras."
                },
                {
                    id: 157,
                    value: "Alterações ortopédicas e/ou dores frequentes (musculares, articulares ou de coluna)"
                },
                {
                    id: 158,
                    value: "Câncer - em tratamento ou em acompanhamento após finalização do tratamento (tratamento finalizado nos últimos 5 anos)"
                },
                {
                    id: 159,
                    value: "Insuficiência Renal Crônica"
                },                
                {
                    id: 15.1,
                    value: "Não"
                },
                {
                    id: 15.2,
                    value: "Não sei"
                }
            ]
        }
    ],
    "Tela 3": [
        {
            id: 16,
            title: "Com que frequência você<label> e seu(s) dependente(s)</label> consome<label>m</label> pelo menos 5 porções de verduras, legumes e/ou frutas no mesmo dia?",
            html_for: "peg16",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "consumo_de_porcoes",
            min_age_condition: 2,            
            answers: [
                {
                    id: 161,
                    value: "Diariamente"
                },
                {
                    id: 162,
                    value: "3 a 4 dias por semana"
                },
                {
                    id: 163,
                    value: "Raramente"
                },
                {
                    id: 164,
                    value: "Nunca"
                },
                {
                    id: 165,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 17,
            title: "Com que frequência são ingeridos alimentos processados e ultraprocessados por você<label> e seu(s) dependente(s)</label>? Ex: temperos prontos, nuggets, hamburguer, enlatados, salgadinhos, refrigerantes, doces, fast food, pizza entre outros.",
            html_for: "peg17",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "frequencia_alimentos_processados",
            min_age_condition: 2,            
            answers: [
                {
                    id: 171,
                    value: "Diariamente"
                },
                {
                    id: 172,
                    value: "Semanalmente"
                },
                {
                    id: 173,
                    value: "Quinzenalmente"
                },
                {
                    id: 174,
                    value: "Raramente"
                },
                {
                    id: 175,
                    value: "Nunca"
                },
                {
                    id: 176,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 18,
            title: "Em média, quantos copos de água (250 ml) você costuma ingerir diariamente?<label> E seu(s) dependente(s)?</label>",
            html_for: "peg18",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "quantos_copos_de_agua",
            min_age_condition: 18,            
            answers: [
                {
                    id: 181,
                    value: "Menos de 4 copos/dia"
                },
                {
                    id: 182,
                    value: "De 4 a 8 copos/dia"
                },
                {
                    id: 183,
                    value: "De 9 a 12 copos/dia"
                },
                {
                    id: 184,
                    value: "De 13 a 16 copos/dia "
                },
                {
                    id: 185,
                    value: "Mais de 16 copos/dia"
                },
                {
                    id: 186,
                    value: "Tenho restrição hídrica"
                },
                {
                    id: 187,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 19,
            title: "Você<label> ou seu(s) dependente(s)</label> ingere<label>m</label> bebidas alcoólicas? Com que frequência?",
            html_for: "peg19",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "ingere_bebida_alcoolica",
            min_age_condition: 12,            
            answers: [
                {
                    id: 191,
                    value: "Não"
                },
                {
                    id: 192,
                    value: "Raramente"
                },

                {
                    id: 193,
                    value: "1 a 2 dias por semana"
                },
                {
                    id: 194,
                    value: "3 a 4 dias por semana"
                },
                {
                    id: 195,
                    value: "5 a 6 dias por semana"
                },
                {
                    id: 196,
                    value: "Diariamente"
                },
                {
                    id: 197,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 20,
            title: "Quantas doses costuma ingerir?",
            subtitle: "1 dose = 1 lata de cerveja, 1 taça de vinho, 1 dose de cachaça, whisky ou qualquer outra bebida alcoólica destilada",
            html_for: "peg20",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "quantas_doses_bebidas",
            question_father: 19,
            expected_answer: ['1 a 2 dias por semana', '3 a 4 dias por semana', '5 a 6 dias por semana', 'Diariamente', 'Raramente'],
            min_age_condition: 12,            
            answers: [
                {
                    id: 201,
                    value: "1"
                },
                {
                    id: 202,
                    value: "2"
                },
                {
                    id: 203,
                    value: "3"
                },
                {
                    id: 204,
                    value: "4"
                },
                {
                    id: 205,
                    value: "Mais de 4"
                },
                {
                    id: 206,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 21,
            title: "Você <label>ou seu(s) dependente(s) </label>realiza<label>m</label> alguma atividade física regular?",
            html_for: "peg21",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "realiza_atividade_fisica",
            min_age_condition: 5,            
            answers: [
                {
                    id: 211,
                    value: "Sim"
                },
                {
                    id: 212,
                    value: "Não"
                },
                {
                    id: 213,
                    value: "Não, pois possuo restrição médica"
                }
            ]
        },
        {
            id: 22,
            title: "Qual a duração?",
            html_for: "peg22",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "duracao_atividade_fisica",
            question_father: 21,
            expected_answer: ['Sim'],
            min_age_condition: 5,            
            answers: [
                {
                    id: 221,
                    value: "Menos de 30 minutos"
                },
                {
                    id: 222,
                    value: "De 30min a 1 hora"
                },
                {
                    id: 223,
                    value: "Entre 1 e 2 horas"
                },
                {
                    id: 224,
                    value: "Mais de 2 horas"
                }
            ]
        },
        {
            id: 23,
            title: "Quantas vezes por semana?",
            html_for: "peg23",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "vezes_semana_atividade_fisica",
            question_father: 21,
            expected_answer: ['Sim'],
            min_age_condition: 5,            
            answers: [
                {
                    id: 231,
                    value: "1 a 2 vezes por semana"
                },
                {
                    id: 232,
                    value: "3 vezes por semana"
                },
                {
                    id: 233,
                    value: "4 vezes por semana"
                },
                {
                    id: 234,
                    value: "5 vezes por semana"
                },
                {
                    id: 235,
                    value: "Mais de 5 vezes por semana"
                }
            ]
        },
        {
            id: 24,
            title: "Qual atividade ou esporte você<label> ou seu(s) dependente(s)</label> pratica<label>m</label>? É possível selecionar mais de uma opção.",
            html_for: "peg24",
            type: "checkbox",
            layout: "horizontal",
            show_question: false,
            column_name: "qual_atividade_fisica",
            question_father: 21,
            expected_answer: ['Sim'],
            min_age_condition: 5,           
            answers: [
                {
                    id: 241,
                    value: "Artes marciais"
                },
                {
                    id: 242,
                    value: "Caminhada"
                },
                {
                    id: 243,
                    value: "Corrida"
                },
                {
                    id: 244,
                    value: "Crossfit"
                },
                {
                    id: 245,
                    value: "Futebol"
                },
                {
                    id: 246,
                    value: "Musculação"
                },
                {
                    id: 247,
                    value: "Natação"
                },
                {
                    id: 248,
                    value: "Dança/Ballet"
                },
                {
                    id: 249,
                    value: "Brincadeiras que envolvam correr, pular, dançar etc."
                },
                {
                    id: 250,
                    value: "Outras"
                }
            ]
        },
        {
            id: 25,
            title: "Nos últimos 30 dias, em média, quantas horas você dormiu diariamente?<label> E seu(s) dependente(s)?</label>",
            html_for: "peg25",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "quantas_horas_sono",
            min_age_condition: 0,            
            answers: [
                {
                    id: 251,
                    value: "Menos de 5 horas"
                },
                {
                    id: 252,
                    value: "5 ou 6 horas"
                },
                {
                    id: 253,
                    value: "7 ou 8 horas"
                },
                {
                    id: 254,
                    value: "Mais de 8 horas"
                },
                {
                    id: 255,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 26,
            title: "Como você classifica a qualidade do seu sono?<label> E de seu(s) dependente(s)?</label>",
            html_for: "peg26",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "qualidade_sono",
            min_age_condition: 0,            
            answers: [
                {
                    id: 261,
                    value: "Ruim"
                },
                {
                    id: 262,
                    value: "Regular"
                },
                {
                    id: 263,
                    value: "Boa"
                },
                {
                    id: 264,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 27,
            title: "Você<label> ou seu(s) depedente(s)</label> fuma<label>m</label>?",
            html_for: "peg27",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "fuma",
            min_age_condition: 12,            
            answers: [
                {
                    id: 271,
                    value: "Sim"
                },
                {
                    id: 272,
                    value: "Não"
                },
                {
                    id: 273,
                    value: "Não, já parei de fumar"
                }
            ]
        },
        {
            id: 28,
            title: "O que você fuma? É possível selecionar mais de uma opção.",
            html_for: "peg28",
            type: "checkbox",
            layout: "vertical",
            show_question: false,
            column_name: "qual_fumo",
            question_father: 27,
            expected_answer: ['Sim'],
            min_age_condition: 12,            
            answers: [
                {
                    id: 281,
                    value: "Cachimbo"
                },
                {
                    id: 282,
                    value: "Charuto"
                },
                {
                    id: 283,
                    value: "Cigarro"
                },
                {
                    id: 284,
                    value: "Cigarro eletrônico"
                },
                {
                    id: 285,
                    value: "Narguilé (Shisha)"
                },
                {
                    id: 286,
                    value: "Outros"
                }
            ]
        }
    ],
    "Tela 4": [
        {
            id: 29,
            title: "Você<label> ou seu(s) dependente(s)</label> pensa<label>m</label> em mudar algum hábito nos próximos 6 meses?",
            html_for: "peg29",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "pensa_em_mudar_habitos",
            min_age_condition: 16,            
            answers: [
                {
                    id: 291,
                    value: "Sim"
                },
                {
                    id: 292,
                    value: "Não"
                },
                {
                    id: 293,
                    value: "Estou tentando mudar"
                },
                {
                    id: 294,
                    value: "Não sei"
                }
            ]
        },
        {
            id: 30,
            title: "Quais hábitos você acredita que necessita ou gostaria de melhorar? É possível selecionar mais de uma opção.",
            html_for: "peg30",
            type: "checkbox",
            layout: "horizontal",
            show_question: false,
            column_name: "quais_habitos_mudar",
            question_father: 29,
            expected_answer: ['Sim', 'Estou tentando mudar'],
            min_age_condition: 16,            
            answers: [
                {
                    id: 301,
                    value: "Atividade física"
                },
                {
                    id: 302,
                    value: "Hábitos alimentares"
                },
                {
                    id: 303,
                    value: "Controle de peso"
                },
                {
                    id: 304,
                    value: "Auto controle de saúde (Cuidar melhor da minha saúde)"
                },
                {
                    id: 305,
                    value: "Saúde emocional"
                },
                {
                    id: 306,
                    value: "Reduzir consumo de bebidas alcoólicas"
                },
                {
                    id: 307,
                    value: "Parar de fumar"
                },
                {
                    id: 308,
                    value: "Outros"
                }
            ]
        }
    ],
    "Tela 5": [
        {
            id: 31,
            title: "Você realizou <label> ou seu(s) dependente(s) realizaram</label> consultas com alguma destas especialidades médicas nos últimos 12 meses? É possível selecionar mais de uma opção.",
            html_for: "peg31",
            type: "checkbox",
            layout: "horizontal",
            show_question: true,
            column_name: "realizou_consulta_medica",
            min_age_condition: 0,            
            answers: [
                {
                    id: 311,
                    value: "Clínico geral"
                },
                {
                    id: 312,
                    value: "Ginecologista/Urologista",
                },
                {
                    id: 313,
                    value: "Pediatra",
                    max_age_condition: 13,
                },
                {
                    id: 314,
                    value: "Endocrinologista"
                },
                {
                    id: 315,
                    value: "Cardiologista"
                },
                {
                    id: 316,
                    value: "Outras"
                },
                {
                    id: 317,
                    value: "Não"
                }
            ]
        },
        {
            id: 32,
            title: "Você fez<label> ou seu(s) dependente(s) fizeram</label> exames laboratoriais preventivos ou de rotina no último ano? Ex: colesterol, glicemia, hemograma",
            html_for: "peg32",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "fez_exame_laboratorial",
            min_age_condition: 0,            
            answers: [
                {
                    id: 321,
                    value: "Sim"
                },
                {
                    id: 322,
                    value: "Não"
                }
            ]
        },
        {
            id: 33,
            title: "Quando você fez seu último exame de papanicolau?",
            html_for: "peg33",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "exame_papanicolau",
            genre_expected_answer: 'Feminino', 
            min_age_condition: 25,            
            answers: [
                {
                    id: 331,
                    value: "Nunca fiz"
                },
                {
                    id: 332,
                    value: "Menos de 1 ano"
                },
                {
                    id: 333,
                    value: "Entre 1 e 3 anos"
                },
                {
                    id: 334,
                    value: "Mais de 3 anos"
                },
                {
                    id: 335,
                    value: "Não me lembro"
                }
            ]
        },
        {
            id: 34,
            title: "Quando você fez o seu último exame de sangue - PSA?",
            html_for: "peg33",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "exame_psa",
            genre_expected_answer: 'Masculino', 
            min_age_condition: 49,            
            answers: [
                {
                    id: 341,
                    value: "Nunca fiz"
                },
                {
                    id: 342,
                    value: "Menos de 1 ano"
                },
                {
                    id: 343,
                    value: "Mais de 1 ano"
                },
                {
                    id: 344,
                    value: "Não me lembro"
                }
            ]
        },
        {
            id: 35,
            title: "Quando você fez seu último exame de mamografia?",
            html_for: "peg35",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "exame_mamografia",
            genre_expected_answer: 'Feminino', 
            min_age_condition: 50,            
            answers: [
                {
                    id: 351,
                    value: "Nunca fiz"
                },
                {
                    id: 352,
                    value: "Menos de 1 ano"
                },
                {
                    id: 353,
                    value: "Mais de 1 ano"
                },
                {
                    id: 354,
                    value: "Mais de 2 anos"
                },                
                {
                    id: 355,
                    value: "Não me lembro"
                }
            ]
        }
    ],
    "Tela 6": [
        {
            id: 36,
            title: "Você já realizou algum teste para COVID-19?<label> E seu(s) dependente(s)?</label>",
            html_for: "peg36",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "fez_teste_de_covid",
            min_age_condition: 0,            
            answers: [
                {
                    id: 361,
                    value: "Sim e o resultado foi positivo"
                },
                {
                    id: 362,
                    value: "Sim e o resultado foi negativo"
                },
                {
                    id: 363,
                    value: "Tive sintomas porém não realizei o teste"
                },
                {
                    id: 364,
                    value: "Nunca realizei o teste"
                }
            ]
        },
        {
            id: 37,
            title: "Você costuma tomar a vacina contra a gripe (influenza) todos os anos?<label> E seu(s) dependente(s)?</label>",
            html_for: "peg37",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "se_imuniza_contra_influenza",
            min_age_condition: 0,            
            answers: [
                {
                    id: 371,
                    value: "Sim"
                },
                {
                    id: 372,
                    value: "Não"
                },
                {
                    id: 373,
                    value: "Não, mas entendo que deveria"
                },
                {
                    id: 374,
                    value: "Não, pois tenho receio de reações da vacina"
                },
                {
                    id: 375,
                    value: "Não, pois possuo contraindicação"
                }
            ]
        },
        {
            id: 38,
            title: "Você já tomou a vacina contra a COVID-19?<label> E seu(s) dependente(s)?</label>",
            html_for: "peg38",
            type: "radio",
            layout: "vertical",
            show_question: true,
            column_name: "imunizou_contra_covid",
            min_age_condition: 0,            
            answers: [
                {
                    id: 381,
                    value: "Sim"
                },
                {
                    id: 382,
                    value: "Não"
                }
            ]
        },
        {
            id: 39,
            title: "Quantas doses você já tomou?",
            html_for: "peg39",
            type: "radio",
            layout: "vertical",
            show_question: false,
            column_name: "quantas_doses_vacina_covid",
            question_father: 38,
            expected_answer: ['Sim'],
            min_age_condition: 0,            
            answers: [
                {
                    id: 391,
                    value: "1"
                },
                {
                    id: 392,
                    value: "2"
                },
                {
                    id: 393,
                    value: "3 ou mais"
                }
            ]
        }
    ]
}