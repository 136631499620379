import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const ContainerQuestion = styled.div`
    margin: 20px 0;
`;

export const Table = styled.table`
    margin: 20px 0;
    border: 1px solid #eee;
    width: 100%;
`;
export const Tr = styled.tr`
    border: 1px solid #eee;    
`;
export const Td = styled.td`
    border: 1px solid #eee;
    min-width: 35px;    
    font-size: 12px;
    text-align: center;    
    ${props => {
        if (props.inputType === "text") {
            return `
                padding: 0;
            `;
        }
        else{
            return `padding: 15px 0;`;
        }
    }}
    .month{
        width: 90%;
        padding: 5px 15px;
        border: 1px solid #eee;
        height: 35px;
    } 
`;
export const Thead = styled.thead`
    background: #222;
    color: #eee;
`;

export const Tbody = styled.tbody`
    margin: 50px;
`;

export const OptionRow = styled.div`
    height: 35px;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    display: flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 650px) {
        height: 50px;
        font-size: 10px;
    }
    @media (max-width: 450px) {
        height: 55px;
        font-size: 10px;
    }
    @media (max-width: 250px) {
        height: 60px;
        font-size: 9px;
    } 
`;

export const Option = styled.input`
  cursor: pointer;
  width: 100%;

  ${props => {
    switch (props.type) {
      case "text":
        return `
          border: none;
          height: 50px;
          padding: 0px 5px;
          cursor: pointer;
        `;
      case "checkbox":
        return `
          height: 19px;
          accent-color: green;
          background-color: green;
        `;
      case "radio":
        return `
            height: 19px;
            accent-color: green;
            background-color: green;
        `;
      default:
        return '';
    }
  }}
`;

export const Label = styled.label`
    font-size: 12px;
`;

export const Title = styled.h1`
    width: 100%;
    color: #555;
    font-size: 14px;
    font-weight: 200;
`;