import styled, { createGlobalStyle } from "styled-components";
import imageBackground from "./assets/bg.png"

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    html, body, #root{
        width: 100%;
        background-image: url(${imageBackground});
        background-size: contain;
        font-family: 'Roboto', sans-serif;
        @media (max-width: 720px) {
            background: none;
        }
    }
    #root{
        color: #eee;
        display: flex;
        justify-content: center;
    }
`;

export const Container = styled.div`
    width: 900px;
    background: #FFF;
    box-shadow: 1px 1px 10px 1px #999;
    border-radius: 3px;
    font-size: 14px;
    color: #666;
    font-family: Arial, Helvetica, sans-serif;
    @media (max-width: 900px) {
        width: 100%;
    }
    @media (max-width: 720px) {
        width: 100%;
        box-shadow: none;
        input[type="radio"]{
            width: auto!important;
            margin-right: 5px;
        }
    }
    @media (max-width: 450px) {
        font-size: 11px;
    }
`;
export const Body = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
    @media (max-width: 720px) {
        padding: 10px;
    }
    @media (max-width: 450px) {
        padding: 0px;
    }
`;

export const Header = styled.div`
    width: 1060px;
    height: 90px;
    margin-bottom: 10px;
    background-image: url(${props => props.logo});
    background-repeat: no-repeat;
    background-size: auto;
    background-attachment: local;
    @media (max-width: 1060px) {
        width: 100%;
    }
    @media (max-width: 900px) {
        background-size: 100%;
    }
    @media (max-width: 720px) {
        width: 100%;
    }
`;

export const ContainerData = styled.div`
    width: 100%;
    height: 100%;
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
`;

export const ButtonsGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 720px) {
        flex-direction: column;
    }
`;

export const Button = styled.button`
    border: none;
    padding: 10px 25px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    margin: 10px;
    color: #eee;
    background: ${props => { 
        if(props.type.toLowerCase() === "avançar" ||  props.type.toLowerCase() === "eu concordo"){ return "#055c05"}
        else if(props.type.toLowerCase() === "abandonar" || props.type.toLowerCase() === "encerrar"){ return "#7f0000"}
        else{ return "#333" }
     }};
     margin-left: ${props => { 
        if(props.redirect === "quit"){ return "250px"}
     }};
    cursor: pointer;
    height: 40px;
    span{
        color: #999;
        font-size: 10px;
    }

    @media (max-width: 900px) {
        margin-left: ${props => { 
            if(props.redirect === "quit"){ return "150px"}
        }};
    }

    @media (max-width: 880px) {
        margin-left: ${props => { 
            if(props.redirect === "quit"){ return "80px"}
        }};
    }

    @media (max-width: 720px) {
        padding: 5px 15px;
        margin-left: 0;
        width: 90%;
    }

    @media (max-width: 620px) {
        padding: 2px 15px;
        width: 90%;
    }
`;