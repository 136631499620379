import React from 'react';

import { Container, Label, Field, SubLabel } from './styles';

const Input = (props) => {
  return(
      <Container>
          <Label>{props.text} <SubLabel>{props.subtext}</SubLabel>: </Label>
          <Field onChange={props.onChange} value={props.value} />
      </Container>
  );
}

export default Input;