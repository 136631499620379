import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useSearch } from '../../providers/search';

import Answers from '../../components/Answers';

import { 
    ContainerQuestion, OptionGroup, OptionContent,
    OptionTitle, OptionRow, AnswersContent, Table,
    Title, Subtitle, Thead, Tbody, Tr, Td, Label  } from './styles';

const Form = ({ questionIndex, question, currentScreen }) => {
    const {search} = useSearch();
    let showButtonResponse = search.questions[`Tela ${currentScreen}`][questionIndex].show_question; 
    var showQuestion = showButtonResponse;
    
    const context = JSON.parse(localStorage.getItem('cache'));

    useEffect(()=>{
        handleConditionalQuestion();
    });
    
    function handleConditionalQuestion(){
        var beneficiariesData = search.beneficiaries;
        var searchData = search;

        searchData.questions[`Tela ${currentScreen}`].forEach((currentQuestion, index) => {            
            var expected_answer = currentQuestion.expected_answer;
            if(expected_answer !== undefined && expected_answer !== null) {
                for(var i = 0; i <= beneficiariesData.length-1; i++) {
                    if(beneficiariesData[i].answers[currentQuestion.question_father] === undefined || beneficiariesData[i].answers[currentQuestion.question_father] === null) {continue;}                    
                    for(var j = 0; j <= expected_answer.length-1; j++){
                        if(beneficiariesData[i].answers[currentQuestion.question_father].includes(expected_answer[j])){
                            currentQuestion.show_question = true;
                            context['questions'][`Tela ${currentScreen}`][index].show_question = true;
                            return;
                        }
                    }     
                    currentQuestion.show_question = false;     
                    context['questions'][`Tela ${currentScreen}`][index].show_question = false;               
                }               
            }  
            else{                
                handleQuestionConfigConditional(currentQuestion, beneficiariesData);
            }      
        });
        
    }

    function handleQuestionConfigConditional(question, beneficiary){
        if(question.genre_expected_answer === undefined){ return null; }
        
        for(var i = 0; i <= beneficiary.length; i++) {
            if(beneficiary[i] === undefined) {continue}
            if (beneficiary[i].age >= question.min_age_condition 
                && beneficiary[i].genre === question.genre_expected_answer){
                    question.show_question = true;
                    return;
                } 
                question.show_question = false;
        }        
    }

    function setBeneficiary(beneficiares, index){
        index === 0 ?
            beneficiares[index] = 'Titular'
        :
            beneficiares[index] = `Dependente ${i}`;
        return beneficiares
    }

    var beneficiares = []
    for(var i = 0; i <= localStorage.getItem('numberPeoples'); i++){
        if(question.genre_expected_answer !== undefined){
            var beneficiariesData = search.beneficiaries[i] ;
            if(beneficiariesData.genre === question.genre_expected_answer && beneficiariesData.age >= question.min_age_condition ){
                if(question.question_father !== undefined){
                    if(
                    beneficiariesData.answers[question.question_father] !== undefined && 
                    beneficiariesData.answers[question.question_father][0] === "Sim"){
                        beneficiares = setBeneficiary(beneficiares, i);
                    }
                }
                else{
                    beneficiares = setBeneficiary(beneficiares, i);
                }
            }
        }
        else{
            if(context['beneficiaries'][i].age >= question.min_age_condition){
                var beneficiariesAnswers = search.beneficiaries[i].answers[question.question_father];
                var questionsData = search.questions[`Tela ${currentScreen}`][questionIndex].expected_answer; 
                if(beneficiariesAnswers !== undefined && beneficiariesAnswers !== null && beneficiariesAnswers !== []){
                    for(var j = 0; j <= questionsData.length-1; j++){
                        if(beneficiariesAnswers.includes(questionsData[j])){
                            beneficiares = setBeneficiary(beneficiares, i);
                        }
                    }
                }else{
                    if(question.question_father === undefined || question.question_father === null){
                        beneficiares = setBeneficiary(beneficiares, i);
                    }
                }
            }
        }
    }

    return(
        showQuestion && <ContainerQuestion key={question.id} className="horizontal">
            <Title 
                show={
                    context === null ||
                    context.beneficiaries === null || 
                    context.beneficiaries.length === 1 
                    ? 
                        true 
                    : 
                        false
                } 
                text={ReactHtmlParser(question.title)} 
            />   

            {question.subtitle !== undefined &&   
                <Subtitle>{ReactHtmlParser(question.subtitle)}</Subtitle>   
            }   
            {question.layout === 'horizontal' ?
                <OptionGroup>
                    <OptionContent>
                        <OptionTitle>Pergunta</OptionTitle>
                        {question.answers.map((answer, index)=>{ 
                            return(<OptionRow key={index}>{answer.value}</OptionRow>)
                        })} 
                    </OptionContent>
                    <AnswersContent>
                        {beneficiares.map((beneficiary, userIndex)=>{
                            return(                     
                                <OptionContent key={beneficiary + userIndex}>
                                    { localStorage.getItem('somente_titulares') === 'false'
                                        ?
                                        <OptionTitle>{beneficiary}</OptionTitle> 
                                        :
                                        <OptionTitle>Respostas</OptionTitle>
                                    } 

                                    <Answers key={userIndex} currentScreen={currentScreen} handleConditionalQuestion={handleConditionalQuestion} beneficiary={beneficiary} question={question} userIndex={userIndex} />
                                </OptionContent> 
                            )
                        })} 
                    </AnswersContent>
                </OptionGroup> 
            :
                <Table  cellSpacing="0" cellPadding="0">
                    <Thead>
                        <Tr> 
                            { localStorage.getItem('somente_titulares') === 'false' &&
                                <Td><Label>Beneficiário(a)</Label></Td>
                            }
                            
                            {question.answers.map((answer, index)=>{ 
                                return(<Td key={`${question.id}-${answer.id}`} inputType={question.type} ><Label htmlFor={`${question.html_for}-${index}`}>{answer.value}</Label></Td>)
                            })} 
                        </Tr>  
                    </Thead>
                    <Tbody>
                        {beneficiares.map((beneficiary, userIndex)=>{
                            return(
                                <Answers key={userIndex} currentScreen={currentScreen} handleConditionalQuestion={handleConditionalQuestion} beneficiary={beneficiary} question={question} userIndex={userIndex} />
                            )
                        })}
                    </Tbody>
                </Table>
            }
        </ContainerQuestion>
    );
}

export default Form;