import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../service/api';
import RedirectButton from '../../components/RedirectButton';

import { Container,  Text, Strong, CardContainer, CardImage, Image, Title, BeneficiaryGroup, BeneficiaryButton, ConclusionContainer, List } from './styles';

const AnsweredQuestionnaire = () => {
    const history = useHistory();
    const [beneficiaryIndex, setBeneficiaryIndex] = useState(0);
    const [showFeedbackButton, setShowFeedbackButton] = useState(true);
    const [showQuitButton, setShowQuitButton] = useState(true);

    const responseFeedback = handleFeedbacks(beneficiaryIndex);
    const beneficiariesFeedback = responseFeedback[0];
    const conclusionsFeedback = responseFeedback[1];

    const holderName = JSON.parse(localStorage.getItem('cache'))['userData']['name'].split(' ')[0] || '';

    const notify = (message) => toast.success(message);

    useEffect(()=>{
        if(localStorage.getItem('current_page') < 4){
            history.push(`/${localStorage.getItem('current_page')}`);
        }
    });

    async function sendEmail(feedbacks, conclusions){
        const account = JSON.parse(localStorage.getItem('account'));
        const to = JSON.parse(localStorage.getItem('cache'))['userData']['email'];
        const data = { feedbacks, conclusions, to, account }
        const response = await api.post(`/api/send-feedback`, data);            
        if(response.status !== 200){ 
            throw new Error(response.data);
        };

        notify("E-mail enviado!");
        return response.status;
    }

    function handleFeedbacks(index){
        let feedbacksPos = [];
        let feedbacksContra = [];
        let feedbacksConclusions = [];
        const holderAnswers = JSON.parse(localStorage.getItem('cache'))['beneficiaries'][index]['answers'];
        const holderGenre = JSON.parse(localStorage.getItem('cache'))['beneficiaries'][index]['genre'];
        const holderAge = JSON.parse(localStorage.getItem('cache'))['beneficiaries'][index]['age'];
        const holderIMC = JSON.parse(localStorage.getItem('cache'))['beneficiaries'][index]['classifierImc'];        
        const beneficiaryName = JSON.parse(localStorage.getItem('cache'))['beneficiaries'][index]['name'].split(' ')[0];
        const userName = JSON.parse(localStorage.getItem('cache'))['userData']['name'].split(' ')[0] || '';
        let kinship = JSON.parse(localStorage.getItem('cache'))['beneficiaries'][index]['kinship'];
        kinship = `${ index === 0 ? userName: beneficiaryName }`
        
        if(holderAnswers[1][0] === 'Bom'){
            let newValue = { 
                'Percepção de saúde': 'A forma como você percebe sua saúde é boa! Busque manter o autocuidado físico, mental e emocional para que esta percepção possa manter-se ao longo dos anos.',
                'image': '/assets/feedback/percepcao_de_saude.png',
                'color': 'green', 
                'kinship': kinship
            }
            feedbacksPos.push(newValue);

        }
        else {
            let newValue = { 
                'Percepção de saúde': 'A forma como você percebe sua saúde não é boa! Que tal buscar apoio profissional para te ajudar a melhorar os pontos que te incomodam?',
                'image': '/assets/feedback/percepcao_de_saude.png',
                'color': 'red', 
                'kinship': kinship
            }
            feedbacksConclusions.push('Busque melhorar os pontos que o incomodam em sua saúde.');
            feedbacksContra.push(newValue);
        }

        if(holderAnswers[2][0] === 'Não'){
            let newValue = { 
                'Condições de saúde': `Busque manter-se com este perfil de saúde através de hábitos saudáveis. Não se esqueça: mesmo na ausência de doenças crônicas, a visita regular ao seu médico de confiança e a realização dos exames preventivos são fundamentais e fazem parte do seu autocuidado de saúde.`,
                'image': '/assets/feedback/condicoes_de_saude.png',
                'color': 'green', 
                'kinship': kinship
            }
            feedbacksPos.push(newValue);
        }
        else{
            let newValue = { 
                'Condições de saúde': `Busque manter o autocontrole de sua saúde através de bons hábitos, adesão às consultas e exames de rotina além do monitoramento de seus sinais de controle (pressão arterial, glicemia, peso entre outros). O tempo investido em você nunca é perdido!`,
                'image': '/assets/feedback/condicoes_de_saude.png',
                'color': 'red', 
                'kinship': kinship
            }
            feedbacksConclusions.push('Faça consultas e exames regulares para controlar sua saúde.');
            feedbacksContra.push(newValue);
        }

        if(holderGenre === 'Feminino' && holderAge > 10 && holderAnswers[7][0] === 'Sim'){
            if(holderAnswers[9][0] === 'Não'){
                let newValue = { 
                    'Gestação': 'Ainda não faz o pré-natal? Este cuidado pode reduzir os riscos gestacionais. Por este motivo, recomendamos que busque orientação médica de um ginecologista obstetra.',
                    'image': '/assets/feedback/gestacao.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Agende uma consulta para iniciar seu pré-natal.');
                feedbacksContra.push(newValue);
            }
            else{
                let newValue = { 
                    'Gestação': 'Parabéns! A realização do pré-natal é de fundamental importância para sua saúde e para o desenvolvimento saudável do seu bebê.',
                    'image': '/assets/feedback/gestacao.png',
                    'color': 'green', 
                    'kinship': kinship
                }

                feedbacksPos.push(newValue);
            }
        }     
        
        if(holderAge >= 5){
            if(holderAnswers[21][0] === 'Não'){
                let newValue = { 
                    'Atividade física': 'Este é um hábito muito importante. Caso se sinta pronto para iniciar este novo hábito ou retomá-lo, busque em sua rede credenciada ou com seu médico fidelizado a avaliação eliberação para iniciar suas atividades. Qualquer movimento conta, com certeza valerá apena!',
                    'image': '/assets/feedback/atividade_fisica.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Reflita sobre iniciar uma atividade física.');
                feedbacksContra.push(newValue);
            }
            else if(holderAnswers[21][0] === 'Sim'){
                if(holderAnswers[23][0] === '1 a 2 vezes por semana' || holderAnswers[23][0] === '3 vezes por semana'){
                    let newValue = { 
                        'Atividade física': 'A atividade física é um fator importante na prevenção e o controle das Doenças Não Transmissíveis (DNTs), controle de peso, saúde mental e bem estar geral. Vale a pena investir um pouco mais na regularidade e duração deste hábito para que ele se torne ainda mais benéfico para sua saúde.',
                        'image': '/assets/feedback/atividade_fisica.png',
                        'color': 'green', 
                        'kinship': kinship 
                    }
                    feedbacksConclusions.push('Reflita sobre aumentar a rotina e/ou frequência de sua atividade física.');
                    feedbacksPos.push(newValue);
                }
                else{
                    if(holderAnswers[22][0] === 'Menos de 30 minutos'){
                        let newValue = { 
                            'Atividade física': 'A atividade física é um fator importante na prevenção e o controle das Doenças Não Transmissíveis (DNTs), controle de peso, saúde mental e bem estar geral. Vale a pena investir um pouco mais na regularidade e duração deste hábito para que ele se torne ainda mais benéfico para sua saúde.',
                            'image': '/assets/feedback/atividade_fisica.png',
                            'color': 'green', 
                            'kinship': kinship 
                        }
                        feedbacksConclusions.push('Reflita sobre aumentar a rotina e/ou frequência de sua atividade física.');
                        feedbacksPos.push(newValue);
                    }
                    else{
                        let newValue = { 
                            'Atividade física': 'Parabéns! A atividade física é um fator importante na prevenção e o controle das Doenças Não Transmissíveis (DNTs), controle de peso, saúde mental e bem estar geral. Invista na regularidade deste hábito e aproveite os benefícios!',
                            'image': '/assets/feedback/atividade_fisica.png',
                            'color': 'green', 
                            'kinship': kinship 
                        }
                        feedbacksPos.push(newValue);
                    }
                }
            }
        }

        if(holderAge >= 12){
            if(holderAnswers[27][0] === 'Não'){
                let newValue = { 
                    'Tabagismo': 'Parabéns pela escolha de não fumar. Continue assim!',
                    'image': '/assets/feedback/tabagismo.png',
                    'color': 'green', 
                    'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
            else if(holderAnswers[27][0] === 'Sim'){
                let newValue = { 
                    'Tabagismo': 'Já pensou modificar este hábito? Atualmente existem profissionais e diversos programas que podem te auxiliar neste processo. Procure entender a sua maior motivação, isso será fundamental para o sucesso desta jornada.',
                    'image': '/assets/feedback/tabagismo.png',
                    'color': 'red', 
                    'kinship': kinship 
                }
                feedbacksConclusions.push('Reflita sobre a possibilidade de parar de fumar.');
                feedbacksContra.push(newValue);
            }
            else if (holderAnswers[27][0] === 'Não, já parei de fumar'){
                let newValue = { 
                    'Tabagismo': '"Parabéns! Procure manter-se no foco e no caso de recaídas, se necessário, não deixe de buscar apoio profissional."',
                    'image': '/assets/feedback/tabagismo.png',
                    'color': 'green', 
                    'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
        }

        if(holderAge >= 16){
            if(holderAnswers[29][0] === 'Sim'){
                let newValue = { 'Predisposição à mudanças': `
                Parabéns! A decisão de modificar seus hábitos pode ser fundamental para sua saúde. O planejamento é muito importante para que você possa atingir seu objetivo sem recaídas:
                <br />
                <li>Procure estabelecer metas específicas e mensuráveis de curto prazo e estabeleça um prazo para concluí-las.</li>
                <li>Pequenas metas concluídas a longo prazo, trazem grandes mudanças.</li>
                `,
                'image': '/assets/feedback/predisposicao_a_mudancas.png',
                'color': 'green', 
                'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
            else if(holderAnswers[29][0] === 'Não' || holderAnswers[29][0] === 'Não sei'){
                let newValue = { 
                    'Predisposição à mudanças': 'A decisão de melhorar os seus hábitos pode ser fundamental para melhorar sua saúde e qualidade de vida. Refletir sobre sua rotina atual e escolhas diárias podem ser um ponto de partida para entender se há alguma mudança positiva que possa ser realizada. Informação e reflexão são fundamentais!',
                    'image': '/assets/feedback/predisposicao_a_mudancas.png',
                    'color': 'red', 
                    'kinship': kinship 
                }
                feedbacksConclusions.push('Reflita sobre a melhoria de hábitos em sua rotina.');
                feedbacksContra.push(newValue);
            }
            else if(holderAnswers[29][0] === 'Estou tentando mudar'){
                let newValue = { 
                    'Predisposição à mudanças': `
                    Parabéns! A decisão de modificar seus hábitos pode ser fundamental para sua saúde. Estratégias para evitar recaídas, fazem com que você permaneça dentro do processo de mudança. Seguem algumas dicas:
                    <br />
                    <li>Tenha sempre um plano alternativo caso as coisas não saiam como o planejado.</li>
                    <li>Não desista! Você está em fase de mudança e recaídas podem acontecer</li>
                    <li>Trace uma nova meta e planeje a retomada deste processo.</li>
                    `,
                    'image': '/assets/feedback/predisposicao_a_mudancas.png',
                    'color': 'green', 
                    'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
            else{
                let newValue = { 
                    'Predisposição à mudanças': 'A decisão de modificar seus hábitos pode ser fundamental para sua saúde. Reflita sobre sua rotina escolhas diárias, há alguma mudança positiva que possa ser realizada? Informação nesta fase é fundamental!',
                    'image': '/assets/feedback/predisposicao_a_mudancas.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Reflita sobre a melhoria de hábitos em sua rotina.');
                feedbacksContra.push(newValue);
            }   
        }


        if(
            (holderAnswers[11][0] === 'Não sei' && holderAnswers[12][0] === 'Nenhuma vez') || 
            (holderAnswers[11][0] === 'Nenhuma vez' && holderAnswers[12][0] === 'Não sei')
        ){
            let newValue = { 'Risco emocional': `Você tem refletido se os seus pensamentos, ideias e sentimentos estão em harmonia? A autoreflexão e o autocuidado podem ser fundamentais para manutenção e/ou melhora de sua saúde emocional.`,
            'image': '/assets/feedback/estado_emocional.png',
            'color': 'red', 
            'kinship': kinship
            }
            feedbacksConclusions.push('Cuide bem de sua saúde emocional.');
            feedbacksContra.push(newValue);
        }
        else if(
            (holderAnswers[11][0] === 'Não sei' && holderAnswers[12][0] === 'Não sei') 
        ){
            let newValue = { 'Risco emocional': `Você tem refletido se os seus pensamentos, ideias e sentimentos estão em harmonia? A autoreflexão e o autocuidado podem ser fundamentais para manutenção e/ou melhora de sua saúde emocional.`,
            'image': '/assets/feedback/estado_emocional.png',
            'color': 'red', 
            'kinship': kinship
            }
            feedbacksConclusions.push('Cuide bem de sua saúde emocional.');
            feedbacksContra.push(newValue);
        }
        else if(
            (holderAnswers[11][0] === 'Nenhuma vez' && (holderAnswers[12][0] === 'Alguns dias' || holderAnswers[12][0] === 'Mais da metade dos dias' || holderAnswers[12][0] === 'Quase todos os dias')) || 
            (holderAnswers[12][0] === 'Nenhuma vez' && (holderAnswers[11][0] === 'Alguns dias' || holderAnswers[11][0] === 'Mais da metade dos dias' || holderAnswers[11][0] === 'Quase todos os dias'))
            ){
                let newValue = { 'Risco emocional': `Fique atento! Valorize o seu bem-estar físico e emocional e se necessário busque auxílio médico.`,
                'image': '/assets/feedback/estado_emocional.png',
                'color': 'red', 
                'kinship': kinship
                }
                feedbacksConclusions.push('Cuide bem de sua saúde emocional.');
                feedbacksContra.push(newValue);
        }
        else if(
            (holderAnswers[11][0] === 'Não sei' && (holderAnswers[12][0] === 'Alguns dias' || holderAnswers[12][0] === 'Mais da metade dos dias' || holderAnswers[12][0] === 'Quase todos os dias')) || 
            (holderAnswers[12][0] === 'Não sei' && (holderAnswers[11][0] === 'Alguns dias' || holderAnswers[11][0] === 'Mais da metade dos dias' || holderAnswers[11][0] === 'Quase todos os dias'))
            ){
                let newValue = { 'Risco emocional': `Fique atento! Valorize o seu bem-estar físico e emocional e se necessário busque auxílio médico.`,
                'image': '/assets/feedback/estado_emocional.png',
                'color': 'red', 
                'kinship': kinship
                }
                feedbacksConclusions.push('Cuide bem de sua saúde emocional.');
                feedbacksContra.push(newValue);
        }
        else if(
            (holderAnswers[12][0] === 'Alguns dias' || holderAnswers[12][0] === 'Mais da metade dos dias' || holderAnswers[12][0] === 'Quase todos os dias') || 
            (holderAnswers[11][0] === 'Alguns dias' || holderAnswers[11][0] === 'Mais da metade dos dias' || holderAnswers[11][0] === 'Quase todos os dias')
            ){
                let newValue = { 'Risco emocional': `Fique atento! Valorize o seu bem-estar físico e emocional e se necessário busque auxílio médico.`,
                'image': '/assets/feedback/estado_emocional.png',
                'color': 'red', 
                'kinship': kinship
                }
                feedbacksConclusions.push('Cuide bem de sua saúde emocional.');
                feedbacksContra.push(newValue);
        }        

        if(holderAge >= 2){
            if(holderAnswers[16].includes('Diariamente') || holderAnswers[17].includes('Raramente') || holderAnswers[17].includes('Nunca')){
                let newValue = { 
                    'Alimentação': 'A alimentação equilibrada é um grande diferencial para sua saúde e qualidade de vida. Continue assim!',
                    'image': '/assets/feedback/alimentacao.png',
                    'color': 'green', 
                    'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
            else{
                let newValue = { 
                    'Alimentação': 'A alimentação equilibrada é um grande diferencial para sua saúde. Observamos que este equilibrio pode ser melhorado em sua alimentação! Prefira sempre alimentos in natura ou minimamente processados e preparações caseiras a alimentos industrializados. Pequenas mudanças podem ter grandes impactos para melhora e/ou manutenção de sua saúde.',
                    'image': '/assets/feedback/alimentacao.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Mantenha uma alimentação equilibrada.');
                feedbacksContra.push(newValue);
            }  
        }
        
        if(holderAge >= 12){
            if(holderAnswers[19].includes('Não')){
                let newValue = { 
                    'Etilismo': 'Você não consome ou possui um consumo controlado de bebidas alcoólicas em sua rotina. Continue assim!',
                    'image': '/assets/feedback/etilismo.png',
                    'color': 'green', 
                    'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
            else{
                if(holderAnswers[20].includes('1') || holderAnswers[20].includes('2') || holderAnswers[20].includes('3')){
                    let newValue = { 
                        'Etilismo': 'Você não consome ou possui um consumo controlado de bebidas alcoólicas em sua rotina. Continue assim!',
                        'image': '/assets/feedback/etilismo.png',
                        'color': 'green', 
                        'kinship': kinship
                    }
                    feedbacksPos.push(newValue);
                }
                else if(holderAnswers[20].includes('4') || holderAnswers[20].includes('Mais de 4')){
                    let newValue = { 
                        'Etilismo': 'Você sabia que o consumo abusivo de álcool não está ligado necessariamente a frequência diária? Ele pode também estar associado ao número de doses ingeridas por você em uma única ocasião. Fique atento!',
                        'image': '/assets/feedback/etilismo.png',
                        'color': 'red', 
                        'kinship': kinship
                    }
                    feedbacksConclusions.push('Fique atento ao seu consumo de bebidas alcoólicas!');
                    feedbacksContra.push(newValue);
                }
            }
        }

        if(holderAnswers[32][0] === 'Sim'){
            let newValue = { 
                'Preventivos – Exames Laboratoriais': 'Parabéns pelo autocuidado! Exames preventivos são fundamentais na manutenção e controle da saúde. Continue assim!',
                'image': '/assets/feedback/preventivos.png',
                'color': 'green', 
                'kinship': kinship
            }
            feedbacksPos.push(newValue);
        }
        else{
            let newValue = { 
                'Preventivos – Exames Laboratoriais': 'Fique atento! Exames preventivos e consultas regulares com um médico fidelizado são de fundamental importância na manutenção e controle da saúde. Agende uma consulta assim que possível e fique em dia com sua saúde.',
                'image': '/assets/feedback/preventivos.png',
                'color': 'red', 
                'kinship': kinship
            }
            feedbacksConclusions.push('Realize consultas e exames de rotina.');
            feedbacksContra.push(newValue);
        }

        if(holderGenre === 'Feminino' && holderAge >= 25){
            if(holderAnswers[33][0] === 'Nunca fiz'){
                let newValue = { 
                    'Preventivos - Papanicolau': 'O Mistério da Saúde recomenda a realização do exame de papanicolau para todas as mulheres de 25 a 64 anos. Procure um ginecologista e agende uma consulta. Previna-se!',
                    'image': '/assets/feedback/preventivos_papanicolau.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Converse com seu médico e realize o exame de papanicolau conforme indicação.');
                feedbacksContra.push(newValue);
            }
            else if(holderAnswers[33][0] === 'Mais de 3 anos' || holderAnswers[33][0] === 'Não me lembro'){
                let newValue = { 
                    'Preventivos - Papanicolau': 'O Mistério da Saúde recomenda a realização do exame de papanicolau para todas as mulheres de 25 a 64 anos uma vez por ano e, após dois exames anuais negativos, a cada três anos. Procure um ginecologista e agende uma consulta. Previna-se!',
                    'image': '/assets/feedback/preventivos_papanicolau.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Converse com seu médico e realize o exame de papanicolau conforme indicação.');
                feedbacksContra.push(newValue);
            }
            else{
                let newValue = { 
                    'Preventivos - Papanicolau': 'Parabéns pelo autocuidado! Exames preventivos são fundamentais na manutenção e controle da saúde. Continue assim!',
                    'image': '/assets/feedback/preventivos_papanicolau.png',
                    'color': 'green', 
                    'kinship': kinship
                }

                feedbacksPos.push(newValue);
            }
        } 
        
        if(holderGenre === 'Masculino' && holderAge >= 49){
            if(holderAnswers[34][0] === 'Menos de 1 ano'){
                let newValue = { 
                    'Preventivos - PSA': 'O rastreamento das alterações prostáticas, segundo o INCA (Instituto Nacional de Câncer) é recomendado aos paciente que possuam sintomas como a presença de sangue na urina, necessidade freqüente de urinar, especialmente à noite, jato urinário fraco e dor ou queimação ao urinar. Nestes casos, a recomendação é que busquem o urologista tão logo surjam estas alterações para investigação. Fique atento e previna-se!',
                    'image': '/assets/feedback/preventivos_psa.png',
                    'color': 'green', 
                    'kinship': kinship
                }
                feedbacksPos.push(newValue);
            }
            else{
                let newValue = { 
                    'Preventivos - PSA': 'O rastreamento das alterações prostáticas, segundo o INCA (Instituto Nacional de câncer) é recomendado aos paciente que possuam sintomas como a presença de sangue na urina, necessidade freqüente de urinar, especialmente à noite, jato urinário fraco e dor ou queimação ao urinar. Nestes casos, a recomendação é que busquem o urologista tão logo surjam estas alterações para investigação. Fique atento e previna-se!',
                    'image': '/assets/feedback/preventivos_psa.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Agende uma consulta com seu urologista para uma avaliação de rotina, principalmente em caso de sintomas.');
                feedbacksContra.push(newValue);
            }
        }

        if(holderGenre === 'Feminino' && holderAge >= 50){
            if(holderAnswers[35][0] === 'Nunca fiz' || holderAnswers[35][0] === 'Mais de 2 anos' || holderAnswers[35][0] === 'Não me lembro'){
                let newValue = { 
                    'Preventivos - Mamografia': 'A realização do exame de mamografia é recomendada pelo Ministério da Saúde para todas as mulheres entre 50 e 69 anos de idade pelo menos uma vez a cada dois anos. Procure um ginecologista e agende uma consulta. Previna-se!',
                    'image': '/assets/feedback/preventivos_mamografia.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Converse com seu médico e realize uma mamografia assim que possível.');
                feedbacksContra.push(newValue);
            }
            else{
                let newValue = { 
                    'Preventivos - Mamografia': 'Parabéns pelo autocuidado! Exames preventivos são fundamentais na manutenção e controle da saúde. Continue assim!',
                    'image': '/assets/feedback/preventivos_mamografia.png',
                    'color': 'green', 
                    'kinship': kinship
                }

                feedbacksPos.push(newValue);
            }
        } 

        if(holderIMC !== undefined){
            if(holderIMC === 'Muito abaixo do peso' || holderIMC === 'Abaixo do peso' || holderIMC === 'Sobrepeso' ||  holderIMC.includes('Obesidade')){
                let newValue = { 
                    'Estado Nutricional': `Você possui um IMC considerado ${ holderIMC }. Vale lembrar que o resultado não indica questões como o percentual de massa e gordura corporal. Busque apoio e avaliação profissional especializada para melhor direcionamento.`,
                    'image': '/assets/feedback/estado_nutricional.png',
                    'color': 'red', 
                    'kinship': kinship
                }
                feedbacksConclusions.push('Busque avaliação profissional para avaliar e entender a melhor forma de equilibrar seu peso/composição corporal.');
                feedbacksContra.push(newValue);
            }
            else{
                let newValue = { 
                    'Estado Nutricional': 'Você possui um IMC considerado adequado para sua altura. Vale lembrar que o resultado não indica questões como o percentual de massa e gordura corporal. Busque apoio e avaliação profissional especializada para melhor direcionamento.',
                    'image': '/assets/feedback/estado_nutricional.png',
                    'color': 'green', 
                    'kinship': kinship
                }
    
                feedbacksPos.push(newValue);
            }
        }       
        
        return [ feedbacksPos.concat(feedbacksContra), feedbacksConclusions ];
    }

    function handleFeedbackEmail(){
        const beneficiaries = JSON.parse(localStorage.getItem('cache'))['beneficiaries'] || [];

        beneficiaries.forEach((_, i) => {
            const response = handleFeedbacks(i);
            const feedbacks = response[0];
            const conclusions = response[1];
            sendEmail(feedbacks, conclusions);
            
        });
        setShowFeedbackButton(false);        
    }

    function handleQuit(e){
        setShowFeedbackButton(false);     
        setShowQuitButton(false);
    }

    return <Container> 
        <ToastContainer />   
        { showQuitButton && 
        <BeneficiaryGroup>
            {
                JSON.parse(localStorage.getItem('cache'))['beneficiaries'].map((data, i) => {
                    return (
                        <BeneficiaryButton key={i} color={i === beneficiaryIndex ? 'T': 'B'} onClick={e => setBeneficiaryIndex(i)} >
                            { i === 0 ? holderName: `${data['name'].split(' ')[0]} ` }
                        </ BeneficiaryButton>
                    );
                })
            }
            
        </BeneficiaryGroup>
        }    
        { showQuitButton ?     
     
            <>
            {
                beneficiariesFeedback.map((data, i) => {                            
                    return (
                    <CardContainer key={Object.keys(data)[0]} color={Object.values(data)[2]}>
                        <CardImage src={Object.values(data)[1]} />
                        <Text><Strong>{`${Object.keys(data)[0]}`}</Strong><br />{ReactHtmlParser(`${Object.values(data)[0]}`)}</Text>
                    </CardContainer>);
                })
            }
            
            {
                <ConclusionContainer>
                    <Strong>Conclusão</Strong>
                    <br /><br />
                    <Text>Agora que já analisamos os aspectos positivos e negativos em sua saúde, fique alerta para os seguintes pontos:</Text>
                    <br /><br />
                    {
                        conclusionsFeedback.map((data, i) => {
                            return (                    
                                <List key={i} >{ReactHtmlParser(data)}</List>
                            );
                        })
                    }
                </ConclusionContainer>
            }
            </>
            :            
                localStorage.getItem("pesquisa") === "216b9238-ae0f-473b-9e44-6579149c0b20" ? 
                <>
                <img src="/assets/conec.png" height={900} style={{
                    position: "relative",
                }} />
                <div style={{
                    position: "absolute",
                    top: 675,
                }}>
                    <a href='https://bit.ly/instamapconec' style={{
                        backgroundColor: "transparent",
                        padding: "10px 15px",
                        marginLeft: "-169px",
                        marginTop: "-11px",
                        border: "none",
                        cursor: "pointer",
                        color: "transparent"
                    }}>.</a>

                    <button style={{
                        backgroundColor: "transparent",
                        padding: "10px 15px",
                        marginLeft: "20px",
                        marginTop: "-11px",
                        border: "none",
                        cursor: "pointer",
                        color: "transparent"
                    }}><a href='https://bit.ly/lnkdnmapconec'>.</a></button>

                    <button style={{
                        backgroundColor: "transparent",
                        padding: "10px 15px",
                        marginLeft: "18px",
                        marginTop: "-11px",
                        border: "none",
                        cursor: "pointer",
                        color: "transparent"
                    }}><a href='https://bit.ly/fbmapconec'>.</a></button>
                </div>
                </>
                :
                <>
                    <Image src="https://cdn-icons-png.flaticon.com/512/148/148767.png" />
                    <Title>
                        Sucesso!
                    </Title>
                    <Text>
                        Agradecemos muito a sua colaboração em nossa avaliação.
                    </Text>
                </>
        }
        
        { (showFeedbackButton && localStorage.getItem('mapeamento_anonimizado') !== '1') && <RedirectButton pt='Enviar por e-mail' en='' onClick={handleFeedbackEmail} /> }
        { showQuitButton && <RedirectButton pt='Encerrar' en='' onClick={handleQuit} /> }
    </Container>;
}

export default AnsweredQuestionnaire;