import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSearch } from '../../providers/search';

import { InputGroup } from './styles';
import { ContainerData, ButtonsGroup } from '../../global';
import Input  from '../../components/Input';
import RedirectButton from '../../components/RedirectButton';
import { nameMask, cpfMask, phoneMask, validarCPF } from '../../utils/mask';

import { getStorage, setStorage } from '../../utils/storage';
import { validarEmail } from '../../utils/mask';
import api from '../../service/api';

const PersonalData = () => {
    let context = getStorage();
    const {search, setSearch} = useSearch();

    const mapeamentoAnonimizado = parseInt(localStorage.getItem('mapeamento_anonimizado'));
    const emailOpcional = Boolean(localStorage.getItem('email_opcional'));

  
    const [name, setName] = useState(context.userData.name);
    const [cpf, setCPF] = useState(context.userData.cpf);
    const [phone, setPhone] = useState(context.userData.phone);
    const [email, setEmail] = useState(context.userData.email);
    

    const notify = (message) => toast.warn(message);

    const history = useHistory();

    useEffect(()=>{        
        if(localStorage.getItem('current_page') < 2){
            history.push(`/${localStorage.getItem('current_page')}`);
        } 
        
        if(mapeamentoAnonimizado === 1){
            localStorage.setItem('current_page', 3); 
            history.push(`/3`);
        } 

        if(localStorage.getItem('pesquisa') === null){history.push('/erro');}
    });    

    function handleName(event){
        setName(nameMask(event.target.value));        
    }

    function handleEmail(event){
        setEmail(event.target.value);
    }


    function handleCPF(event){
        setCPF(cpfMask(event.target.value));
    }

    function handlePhone(event){
        setPhone(phoneMask(event.target.value));
    }

    async function findUserByCPF(userCPF){
        const data = {
            chave_pesquisa: localStorage.getItem('pesquisa')
        };

        const response = await api.post(`/api/questions/single/${userCPF}`, data);
        if(response.data !== null){
            return false;
        }
        return true;
    }

    async function handleForm(){
        const userData = {
          name,
          cpf,
          phone,
          email
        }

        let hasUser = await findUserByCPF(userData.cpf);
        if(! hasUser){
            notify("Usuário já participou da pesquisa!");
        }
        else{
            if(userData.name === '' || userData.name.split(" ").length <= 1){
                notify("Verifique o nome");
            }
            else if( userData.cpf.length < 14 || ! validarCPF(userData.cpf)){
                notify("Verifique o CPF");
            }
            else if(userData.phone === '' || userData.phone.length < 15){
                notify("Verifique o telefone");
            }
            else if((! validarEmail(userData.email) || userData.email === '') && emailOpcional !== true){
                notify("Verifique o e-mail");          
            }
            else{
                setSearch({ ...search, userData });   
    
                context.userData = userData;
                setStorage(context);
    
                localStorage.setItem('current_page', 3); 
                history.push(`/3`);
            }
        }        
    }

    return(
        <ContainerData>
            <ToastContainer />  
            <InputGroup>
                <Input text="Nome completo (Titular)" onChange={handleName} value={name || ''} />
                <Input text="CPF (Titular)" onChange={handleCPF} value={cpf || ''} />
                <Input
                    text="E-mail (Titular)"
                    subtext={
                        emailOpcional === true
                        ? "* Não obrigatório"
                        : "* Importante para receber uma cópia desta devolutiva"
                    }
                    onChange={handleEmail}
                    value={email || ""}
                />
                <Input text="Celular (Titular)" onChange={handlePhone} value={phone || ''} />
                
                <ButtonsGroup>
                    <RedirectButton redirect="back" pt='Voltar' en='back' onClick={()=>{}} />
                    <RedirectButton pt='Avançar' en='Next' onClick={handleForm} />
                    <RedirectButton redirect="quit" pt='Abandonar' en='Quit Survey' onClick={()=>{}} />
                </ ButtonsGroup>
            </InputGroup>            
        </ContainerData>
    );
}

export default PersonalData;