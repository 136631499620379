import styled from 'styled-components';

export const Text = styled.div`
  text-align: left;
  padding: 0 10%;  
  font-size: 16px;
  p {
    margin-bottom: 25px;
    letter-spacing: 0.3px;
  }
  li{
    margin-left: 20px;
  }
  @media (max-width: 1060px) {
    padding: 0 10%;
    font-size: 14px;
  }
  @media (max-width: 720px) {
    padding: 10px;
    font-size: 12px;
  }
`;

export const PrivacyLink = styled.a`
  color: #666;
  text-decoration: underline;
  margin-top: 10px;
`;
