import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Initial from "./pages/Initial";
import LGPDTerm from "./pages/LGPDTerm";
import PersonalData from "./pages/PersonalData";
import PopulationProfile from "./pages/PopulationProfile";
import Question from "./pages/Question";
import AnsweredQuestionnaire from "./pages/answeredQuestionnaire";
import Erro from "./pages/Erro";

const PrivateRoute = ({ component: Component, ...rest }) => (     
  <Route
      {...rest}
      render={(props) =>
          localStorage.getItem('pesquisa') !== null && localStorage.getItem('pesquisa') !== undefined && localStorage.getItem('pesquisa') !== '' ? (
              <Component {...props} />
          ) : (
              <Redirect to={{ pathname: "/erro", state: { from: props.location } }} />
          )
      }
  />    
);

const Routes = props => (
  <BrowserRouter>
    <Switch>
        <Route exact path="/:corretor/:cliente" component={Initial} />
        <PrivateRoute exact path="/1" component={LGPDTerm} />
        <PrivateRoute exact path="/2" component={PersonalData} />
        <PrivateRoute exact path="/3" component={PopulationProfile} />
        <PrivateRoute exact path="/4" component={Question} />
        <PrivateRoute exact path="/success" component={AnsweredQuestionnaire} />
        <Route exact path="/erro" component={Erro} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
