import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import formatDate from '../../utils/formatDate';
import { useSearch } from '../../providers/search';

import { OptionRow, Tr, Td, Option, Label  } from './styles';
import { getStorage, setStorage } from '../../utils/storage';

const Answers = ({ currentScreen, beneficiary, question, userIndex, handleConditionalQuestion }) => {  
    let context = getStorage();

    const [answers, setAnswers] = useState([]);
    const [answersDate, setAnswersDate] = useState(context.beneficiaries[userIndex].answers[8] || "");

    const {search, setSearch} = useSearch();

    const notifyWarn = (message) => toast.warn(message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    let selectedAnswers = context['beneficiaries'][userIndex]['answers'][question.id];

    function handleCache(data){        
        context['beneficiaries'][userIndex]['questions'][question.id] = question.title;
        context['beneficiaries'][userIndex]['columns'][question.id] = question.column_name;
        context['beneficiaries'][userIndex]['answers'][question.id] = data;

        setStorage(context);    
    }

    function handleAnswer(data){
        var questionsData = search.questions;
        search.beneficiaries[userIndex].answers[question.id] = data;
        
        handleCache(data);

        setAnswers(data);
        setSearch({ ...search,  questions: questionsData });

        context.questions = questionsData;
        setStorage(context); 

        handleConditionalQuestion(); 
    }

    function handleTextAnswer(event) {
        const value = event.target.value;
        handleAnswer([ value ]);
    }

    function handleMonthAnswer(event){
        const value = event.target.value;

        let today = formatDate(new Date());

        let maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + 10);
        maxDate = formatDate(maxDate);
        if(value.concat('-28') >= today && value.concat('-28') <= maxDate){
            handleAnswer(value);
            setAnswersDate(value);           
        }
        else{
            notifyWarn(`Verique a data do campo - ${question.title}`);
        }               
    }

    function checkChildRules(value){
        const questions = context['questions'][`Tela ${currentScreen}`];
        for(var i = questions.length-1; i >= 0; i--){
            const currentQuestion = questions[i];            
            if(currentQuestion.question_father === question.id){ 
                if(currentQuestion !== undefined && currentQuestion.question_father !== undefined && currentQuestion.question_father === question.id){
                    context['beneficiaries'].forEach((beneficiary, beneficiaryId) => {                        
                        if(currentQuestion.expected_answer !== undefined && ! currentQuestion.expected_answer.includes(value)){
                            beneficiary['answers'].forEach((r, i) => {
                                    if(i > currentQuestion.id){
                                        beneficiary['answers'][i] = null;
                                        search.beneficiaries[beneficiaryId].answers[currentQuestion.id] = null;
                                    }
                            })
                            beneficiary['answers'][currentQuestion.id] = [];
                            search.beneficiaries[beneficiaryId].answers[currentQuestion.id] = [];
                        }                        
                    });
                }  
            }
            
        }

        localStorage.setItem('cache', JSON.stringify(context));           
    }

    function handleRadioAnswer(event){
        let { value } = event.target;
        
        checkChildRules(value);
        handleAnswer([value]);         
    }

    function handleCheckboxAnswer(event){
        const { value, checked } = event.target;
        
        let data = answers;
        if(checked){
            if(
            value ===  "Sim, porém nenhuma destas" || 
            value ===  "Não" || 
            value ===  "Não sei" || 
            value ===  "Outras "){
                data = [value];
            }
            else{
                data.push(value);
                data = data.filter(x => 
                    x !==  "Sim, porém nenhuma destas" && 
                    x !== "Não" && 
                    x !== "Não sei" && 
                    x !== "Outras ");
            }
        }
        else{
            data = data.filter(x => x !== value);
        }
        
        checkChildRules(value);
        handleAnswer(data);
    }

    function handleTypeAnswer(question, event){
        if(question.type === 'radio'){
            handleRadioAnswer(event)
        }
        else if(question.type === 'checkbox'){
            handleCheckboxAnswer(event)
        }
        else if(question.type === 'month'){
            handleMonthAnswer(event)
        }
        else if(question.type === 'text'){
            handleTextAnswer(event)
        }
    }

    return question.layout === 'horizontal' ?
        question.answers.map((answer, index) => { 
            return(
                <div key={index}>
                    <ToastContainer /> 
                    <OptionRow>  
                    {question.type === 'month' || question.type === 'text' ?                                                                        
                        <Option 
                            key={index}
                            type={question.type} 
                            className={question.type}
                            id={`${beneficiary}-${question.html_for}-${index}`} 
                            name={`${beneficiary}-${question.html_for}`} 
                            defaultValue={answersDate}
                            onBlur={(e) => handleTypeAnswer(question, e)  } 
                        />  
                    :                                                     
                        <Option 
                            key={index}
                            type={question.type} 
                            className={question.type}
                            id={`${beneficiary}-${question.html_for}-${index}`} 
                            name={`${beneficiary}-${question.html_for}`} 
                            value={answer.value}
                            onChange={(e) => handleTypeAnswer(question, e)  } 
                            checked={selectedAnswers !== undefined && selectedAnswers !== null ? selectedAnswers.includes(answer.value) : false}
                        />
                    }
                    </OptionRow> 
                    
                </ div>                          
            )
        })
    : 
    (                                                   
        <Tr>
            { localStorage.getItem('somente_titulares') === 'false' &&
                <Td><Label>{beneficiary}</Label></Td>
            }
            
            {
                question.answers.map((answer, index)=>{ 
                    return(
                        <Td key={index}  inputType={question.type} >
                            {question.type === 'month' || question.type === 'text' ?                                                                        
                                <Option 
                                    placeholder={ question.type === 'text' ? "Digite sua resposta..." : null}
                                    type={question.type} 
                                    className={question.type}
                                    id={`${beneficiary}-${question.html_for}-${index}`} 
                                    name={`${beneficiary}-${question.html_for}`} 
                                    defaultValue={answersDate}
                                    onBlur={(e) => handleTypeAnswer(question, e)  } 
                                /> 
                            :                                                     
                                <Option 
                                    type={question.type} 
                                    className={question.type}
                                    id={`${beneficiary}-${question.html_for}-${index}`} 
                                    name={`${beneficiary}-${question.html_for}`} 
                                    value={answer.value}
                                    onChange={(e) => handleTypeAnswer(question, e)  } 
                                    checked={selectedAnswers !== undefined && selectedAnswers !== null ? selectedAnswers.includes(answer.value) : false}
                                />
                            }

                            
                        </Td>
                    )
                })
            }
        </Tr>
    );
}

export default Answers;