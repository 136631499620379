import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { useSearch } from '../../providers/search';

import { Text } from './styles';
import RedirectButton from '../../components/RedirectButton';

import { getStorage, setStorage } from '../../utils/storage';

const LGPDTerm = (props) => {
    const history = useHistory();
    const {search, setSearch} = useSearch();

    let context = getStorage();

    function clearUserData(){
        if(search.acceptedTerm === false){
            setSearch({ ...search, 
                    userData: {
                        name: '',
                        cpf: ''
                    } 
                
            });
            
        }
    }

    function handleAcceptedTerm(){        
        setSearch({ ...search, 
            acceptedTerm:true,
            acceptedTermDate: new Date()
        });

        context.acceptedTerm = true;
        context.acceptedTermDate = new Date();

        setStorage(context);

        localStorage.setItem('current_page', 2);
        history.push(`/2`);
    }

    function handleRejectedTerm(){        
        setSearch({ ...search, 
            acceptedTerm:false,
            acceptedTermDate: null
         });    
        clearUserData();
        history.push(`/3`);
    }

    return (
        <Text>
            <br />
            {ReactHtmlParser(localStorage.getItem('texto_lgpd'))}
            <br />
            <br />
            <RedirectButton redirect="back" pt='Voltar' en='back' onClick={()=>{}} />
            <RedirectButton pt='Eu concordo' en='I agree' onClick={handleAcceptedTerm} />
            <RedirectButton redirect="quit" pt='Abandonar' en='Quit Survey' onClick={handleRejectedTerm} />
        </Text>
    );
}

export default LGPDTerm;
