import React, { useState } from 'react';
import { questions } from '../questions';

export const SearchContext = React.createContext({});

export const SearchProvider = (props) => { 

    let context = JSON.parse(localStorage.getItem('cache'));
    if (localStorage.getItem('cache') === null) {
        context = {
            startDate: new Date(),
            finalDate: null,
            acceptedTerm: null,
            acceptedTermDate: null,
            questions,
            userData: {
                name: '',
                cpf: ''
            },
            beneficiaries: [],
        }       
    }

    const [search, setSearch] = useState(context);

    return(
        <SearchContext.Provider value={{search, setSearch}}>
            {props.children}
        </SearchContext.Provider>
    )
}

export const useSearch = () => React.useContext(SearchContext);