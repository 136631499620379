import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSearch } from '../../providers/search';
import { validarEmail } from '../../utils/mask';

import { Title, Text, Input, Note } from './styles';
import { ButtonsGroup } from '../../global';

import ProgressBar from '../../components/ProgressBar';
import PersonalForm from '../../components/PersonalForm';
import RedirectButton from '../../components/RedirectButton';

import { getStorage, setStorage } from '../../utils/storage';
import { validarCPF } from '../../utils/mask';

const PopulationProfile = () => {
    const [numberPeoples, setNumberPeoples] = useState(localStorage.getItem('numberPeoples') || 0);

    const {setSearch} = useSearch();

    const history = useHistory();
    const notify = (message) => toast.warn(message);

    let context = getStorage();

    useEffect(() => {         
        if(localStorage.getItem('current_page') < 3){
            history.push(`/${localStorage.getItem('current_page')}`);
        }
    });
    
    function cleanBeneficiaryData(numberPeoples){      
        context.beneficiaries = context.beneficiaries.filter((item, index)=>{
            return index <= numberPeoples
        });

        setSearch(context);
        setStorage(context);        
    }

    function handleNumberPeoples(event){
        const { value } = event.target;
        setNumberPeoples(value < 0 ? 0: value);
        localStorage.setItem('numberPeoples', value < 0 ? 0: value);

        cleanBeneficiaryData(value);        
    }

    function validateFields(){
        context = getStorage();

        let invalidFields = 0
        let fieldNames = {
            name: "Nome",
            genre: "Gênero",
            email: "E-mail",
            cpf: "CPF",
            kinship: "Parentesco",
            birthday: "Data de aniversário",
            weight: "Peso",
            height: "Altura"
        }
        Object.values(context.beneficiaries).map((data, i)=>{
            if(i <= localStorage.getItem('numberPeoples')){                          
                for(var index in data){
                    if(fieldNames[index] !== undefined){ 
                        if(data['beneficiary'] === "Titular" && (fieldNames[index] === "Nome" || fieldNames[index] === "E-mail" || fieldNames[index] === "Parentesco"  || fieldNames[index] === "CPF")) { continue }

                        if(data['beneficiary'] !== "Titular") {
                            if(localStorage.getItem('mapeamento_anonimizado') !== '1'){
                                if(data['name'] === '' || data['name'].split(" ").length <= 1){
                                    notify(`${data['beneficiary']} - Verifique o nome`);
                                    invalidFields = invalidFields + 1
                                }
                                if(data['cpf'] === '' && (data['cpf'].length < 14 || ! validarCPF(data['cpf']))){
                                    notify(`${data['beneficiary']} - Verifique o CPF`);
                                    invalidFields = invalidFields + 1
                                }
                                if(data['email'] !== "" && ! validarEmail(data['email'])){
                                    notify(`${data['beneficiary']} - Verifique o e-mail`);
                                    invalidFields = invalidFields + 1
                                }
    
                                if(data['weight'] === ''){
                                    notify(`${data['beneficiary']} - Preencha o peso`);
                                    invalidFields = invalidFields + 1
                                }
    
                                if(data['height'] === ''){
                                    notify(`${data['beneficiary']} - Preencha o altura`);
                                    invalidFields = invalidFields + 1
                                }
                                return invalidFields; 
                            }                            
                        } 
                        else{
                            if(data['genre'] === ''){
                                notify(`${data['beneficiary']} - Preencha o gênero`);
                                invalidFields = invalidFields + 1
                            }

                            if(data['weight'] === ''){
                                notify(`${data['beneficiary']} - Preencha o peso`);
                                invalidFields = invalidFields + 1
                            }

                            if(data['height'] === ''){
                                notify(`${data['beneficiary']} - Preencha o altura`);
                                invalidFields = invalidFields + 1
                            }
                            return invalidFields;
                        }                      
                    }
                }
            }  
            return invalidFields;          
        });

        return invalidFields; 
    }

    function handleForm(){
        if(context.beneficiaries[0] !== undefined && context.beneficiaries[0].age >= 16){
            if(validateFields() === 0){
                setSearch(getStorage());
                localStorage.setItem('current_page', 4);
                history.push(`/4`);
            }            
        }
        else{
            notify(`O titular precisa ter 16 anos de idade ou mais`);
        }        
    }

    var rows = [];
    for (var i = 0; i <= numberPeoples; i++) {
        rows.push(<PersonalForm key={i} position={i} beneficiary={(i === 0 )?'Titular':`Dependente ${i}`} />);
    }

    return (
        <>
            <ToastContainer /> 
            <ProgressBar progress={1}></ProgressBar>
            <Title>Dados pessoais</Title>
            <br /><br />
            { localStorage.getItem('somente_titulares') === 'false' &&
                <>
                    <Text>
                    Por favor, preencha a quantidade de dependentes que fazem parte
                    do seu plano de saúde:
                    </Text>
                    <Input
                        type="number"
                        value={numberPeoples}
                        onChange={handleNumberPeoples}
                    />
                    <br /><br />
                    <Note>
                        Nota: Digite seu Peso ou valor aproximado (kg) Coloque valores
                        inteiros (Ex: 78)
                        <br />
                        Caso não saiba o seu peso exato, não tem problema, informe um
                        valor aproximado, ok?
                    </Note>
                </>
            }
            
            <br />
            {rows}
            <br />
            <ButtonsGroup>
                <RedirectButton redirect="back" pt='Voltar' en='back' onClick={()=>{}} />
                <RedirectButton pt='Avançar' en='Next' onClick={handleForm} />
                <RedirectButton redirect="quit" pt='Abandonar' en='Quit Survey' onClick={()=>{}} />
            </ ButtonsGroup>
        </>
    );
}

export default PopulationProfile;