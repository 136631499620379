export const nameMask = value => {
    return value
      .replace(/[0-9]/g, '')
}

export const validarEmail = email => {
    var emailPattern =  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,8})$/;
    return emailPattern.test(email); 
}

export const cpfMask = value => {
    return value
      .replace(/\D/g, '') 
      .replace(/(\d{3})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
}

export const phoneMask = value => {
  return value
    .replace(/\D/g,'')                 
    .replace(/^(\d\d)(\d)/g,'($1) $2') 
    .replace(/(\d{5})(\d)/,'$1-$2') 
    .replace(/(-\d{4})\d+?$/, '$1')   
}

export const validarCPF = cpf => {	
  cpf = cpf.replace(/[^\d]+/g,'');	
  if(cpf === '') return false;		
  if (cpf.length !== 11 || 
      cpf === "00000000000" || 
      cpf === "11111111111" || 
      cpf === "22222222222" || 
      cpf === "33333333333" || 
      cpf === "44444444444" || 
      cpf === "55555555555" || 
      cpf === "66666666666" || 
      cpf === "77777777777" || 
      cpf === "88888888888" || 
      cpf === "99999999999")
          return false;		
  
  var add = 0;	
  for (var i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      var rev = 11 - (add % 11);	
      if (rev === 10 || rev === 11)		
          rev = 0;	
      if (rev !== parseInt(cpf.charAt(9)))		
          return false;		
  
  add = 0;	
  for (i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
  rev = 11 - (add % 11);	
  if (rev === 10 || rev === 11)	
      rev = 0;	
  if (rev !== parseInt(cpf.charAt(10)))
      return false;		
  return true;   
}