import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 5px;
    justify-content: space-between;
    @media (max-width: 620px) {
        margin: 5px;
    }
`;

export const Label = styled.label`
  font-size: 18px;
  color: #333;
`;

export const SubLabel = styled.label`
    font-size: 14px;
    color: #999;
`;

export const Field = styled.input`
    padding: 10px 25px;
    border-radius: 3px;
    color: #444;
    border: 1px solid #d4cece;
    font-weight: 600;
    width: 100%;
    @media (max-width: 620px) {
        padding: 8px 15px;
    }
`;
