import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 40px 0;
`;

export const Bar = styled.ul`
  max-width: 29em;
  width: 100%;
  padding: 0 18px;
  height: 2em;
  margin: 0;
  padding: 0;
  font-size: 10px;
  
    li{
        width: 14%;
        float: left;
        height: 100%;
        list-style: none;
        position: relative;
        margin: 0;
        padding: 0;
    }

    li span{
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: #333;
        height: .4em;
        z-index: 1;
    }

    li.activated:before{
        background: #fff;
        border: 0.3em solid #333;
        box-sizing: border-box;
    }


    li:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: #333;
        width: 2em;
        height: 2em;
        border-radius: 2em;
        z-index: 2;
    }

    li:last-child:after{
        content: '';
        display: block;
        position: absolute;
        right: -2em;
        top: 0;
        background: #333;
        width: 2em;
        height: 2em;
        border-radius: 2em;
        z-index: 2;
        display: none;
    }
`;
