import React from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../global';

import api from '../../service/api';

const RedirectButton = (props) => { 
    const cache = JSON.parse(localStorage.getItem('cache'));   
    const history = useHistory();

    let selectedFunction = props.onClick;
    if(props.redirect === 'quit'){

        selectedFunction = handleQuitSurvey
     }
    else if(props.redirect === 'back'){ selectedFunction = handleBack }

    function handleBack(){
        history.goBack();
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function handleQuitSurvey(){
        let text = "Tem certeza que quer abandonar a pesquisa?";
        let response = false;
        if (window.confirm(text) === true) {
            response = saveQuitSurveyLog();

            while(! response) { continue; }            

            localStorage.clear();
            window.location.href = `/${cache.corretor}/${cache.cliente}`;
        } 
    }

    async function saveQuitSurveyLog(){
        const { name, cpf, phone, email } = cache.userData;

        if(cpf !== "" || cpf !== undefined){ 
            const data = {
                chave_pesquisa: localStorage.getItem('pesquisa'),
                inicio_pesquisa: cache.startDate,
                abandono_pesquisa: new Date(),
                cpf,
                name,
                phone,
                email   
            };

            const response = await api.post(`/api/abandoned`, data);
            
            if(response.status !== 200){ throw new Error(response.data) };

            await delay(2000);
        }
        return true;
    }

    return(
        <Button onClick={selectedFunction} type={props.pt} redirect={props.redirect}>
            {props.pt.toUpperCase()}
        </Button>
    );
}

export default RedirectButton;