import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../service/api';
import { Container  } from './styles';

import { ButtonsGroup } from '../../global';
import ProgressBar from '../../components/ProgressBar';
import RedirectButton from '../../components/RedirectButton';
import Form  from '../../components/Form';

import { getStorage, setStorage } from '../../utils/storage';

import { questions } from '../../questions';
import { imc } from '../../pregnantImc';

const Question = () => {
    let context = getStorage();
    context = calculateImc();

    let lastId = localStorage.getItem('lastId');
    lastId = parseInt(lastId);

    const [showButton, setShowButton] = useState(true);
    const [currentScreen, setCurrentScreen] = useState(1);

    const notifyWarn = (message) => toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    const history = useHistory();

    useEffect(()=>{
        if(localStorage.getItem('current_page') < 4){
            history.push(`/${localStorage.getItem('current_page')}`);
        }
    })

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [currentScreen]);

    function calculateImc(){        
        context.beneficiaries.forEach((data, i) => {
            const { weight, height } = context.beneficiaries[i];            
            context.beneficiaries[i].imc = (parseInt(weight) / Math.pow(height, 2)).toFixed(2);         
        });

        localStorage.setItem('cache', JSON.stringify(context));
        return context;
    }

    async function saveBeneficiaries(userData){
        const { cpf, name, phone } = userData;
        const data = {
            chave_pesquisa: localStorage.getItem('pesquisa'),
            cpf,
            name,
            phone
        };

        const response = await api.post(`/api/beneficiaries`, data);
        
        if(response.status !== 200){ 
            setShowButton(true);
            throw new Error(response.data) 
        };
        return response.data;
    }


    async function saveAnswers(data, type){
        const response = await api.post(`/api/questions/${type}`, data);
        
        if(response.status !== 200){ 
            setShowButton(true);
            throw new Error(response.data);
        };
        return response.status;
    }

    async function saveDinamicAnswers(data){
        const response = await api.post(`/api/answers/dinamic/`, data);
        
        if(response.status !== 200){ 
            setShowButton(true);
            throw new Error(response.data);
        };
        return response.status;
    }

    async function saveData() {
        setShowButton(false);

        const savedUser = await saveBeneficiaries(context.userData);
        context.beneficiaries.forEach(async beneficiary => {  
            let currentLastId = lastId + 1;
            const dinamicAnswers = beneficiary.answers.slice(currentLastId);        
            const singleChoiceAnswers = formatSingleChoiceAnswers(savedUser, beneficiary);
            saveAnswers(singleChoiceAnswers, 'single');

            const multipleChoiceAnswers = formatMultipleChoiceAnswers(savedUser, beneficiary);            
            
            multipleChoiceAnswers.forEach(multipleChoiceAnswer=>{
                saveAnswers(multipleChoiceAnswer, 'multiple');                
            }); 
            if(JSON.parse(localStorage.getItem('settings'))['tem_pergunta_generica'] === true){
                let totalIndex = 0
                for (let pagina = 7; pagina <= 9; pagina++) {         
                    for (const [index, currentQuestion] of context.questions[`Tela ${pagina}`].entries()) { 
                            if(parseInt(currentQuestion.id) === parseInt(localStorage.getItem('lastId')) + 1){  
                                    if(dinamicAnswers[totalIndex]){
                                        for (var dinamicAnswer of dinamicAnswers[totalIndex]) {
                                            const data = {
                                                question_id: currentQuestion.rawId,
                                                answer: dinamicAnswer,
                                                chave_pessoa: beneficiary.chave_pessoa
                                            };
                                            saveDinamicAnswers(data);                                        
                                        }   
                                    }    
                                    
                                    currentLastId += 1    
                                    localStorage.setItem('lastId', parseInt(localStorage.getItem('lastId')) + 1)                                                                               
                        }
                        totalIndex += 1               
                    }
                }
            }
            
        });  
        history.push(`/success`);
    }

    function validateAnsweredQuestions(){
        context = getStorage();

        let emptyAnswer = 0; 
            context.questions[`Tela ${currentScreen}`].forEach((question)=>{                       
                let isMandatoryQuestion = question.show_question;            
                const questionId = question.id;
                const beneficiariesData = JSON.parse(localStorage.getItem('cache'))['beneficiaries'];            
                for(var i = 0; i <= beneficiariesData.length-1; i++) {                
                    const hasAnswers = context.beneficiaries[i].answers[question.question_father];                
                    if(question.question_father !== undefined && hasAnswers !== undefined) {                    
                        if(
                            hasAnswers !== null && 
                            hasAnswers.length !== undefined && 
                            hasAnswers.length > 0
                        ){
                            for(var j=0; j <= hasAnswers.length -1 ; j++){                            
                                isMandatoryQuestion = question.expected_answer.includes(hasAnswers[j]);
                            }
                        }    
                    } 
                        if(isMandatoryQuestion === true && question.min_age_condition  !== undefined && beneficiariesData[i].age >= question.min_age_condition){
                            if(beneficiariesData[i] === undefined){continue}            
                            const answered = beneficiariesData[i]['answers'][questionId];                    
                            if(question.genre_expected_answer === undefined){
                                if(answered === undefined || answered === null || answered.length === 0){
                                    notifyWarn(`Preencha o campo - ${question.title}`);
                                    emptyAnswer += 1;
                                }  
                            }
                            else{
                                if(question.genre_expected_answer === beneficiariesData[i].genre){
                                    if(answered === undefined || answered === null || answered.length === 0){
                                        notifyWarn(`Preencha o campo - ${question.title}`);
                                        emptyAnswer += 1;
                                    }  
                                }                        
                            }
                        }
                    
                }       
            })
        
        return emptyAnswer; 
    }

    function handleBack(){
        currentScreen <= 1? history.push(`/3`) : setCurrentScreen(currentScreen - 1);
    }
    
    function formatMultipleChoiceAnswers(userData, beneficiaryData){ 
        const { chave } = userData.beneficiaries;
        const questionsIndex = [2, 10, 15, 24, 28, 30, 31];
        let data = [];
        questionsIndex.forEach(index => {
            var descriptionAnswers = beneficiaryData.answers[index];
            try{
                descriptionAnswers.forEach(descriptionAnswer => {
                    data.push({
                        chave_pesquisa: localStorage.getItem('pesquisa'),
                        chave_pessoa: beneficiaryData.chave_pessoa,
                        chave_titular: chave,
                        descricao_pergunta: beneficiaryData.questions[index],
                        descricao_resposta: descriptionAnswer,
                    });  
                });
            }catch(err){}

        });

        return data;
    }

    function formatSingleChoiceAnswers(userData, beneficiaryData){ 
        const { chave } = userData.beneficiaries;
        const data = {};

        const multipleQuestions = [2, 10, 15, 24, 28, 30, 31 ];

        if(beneficiaryData.beneficiary === "Titular"){
            data["name"] = context.userData["name"];
            data["cpf"] = context.userData["cpf"];
            data["phone"] = context.userData["phone"] || "";
            data["email"] = context.userData["email"] || "";
            
        }
        else{
            data["name"] = beneficiaryData.name;
            data["cpf"] = beneficiaryData.cpf || "";
            data["phone"] = beneficiaryData.phone || "";
            data["email"] = beneficiaryData.email || "";
            data["parentesco"] = beneficiaryData.kinship || "";
        }

        data["chave_pesquisa"] = localStorage.getItem('pesquisa');
        data["chave_pessoa"] = beneficiaryData.chave_pessoa;
        data["chave_titular"] = chave;
        data["inicio_pesquisa"] = context.startDate;
        data["fim_pesquisa"] = new Date();        
        data["beneficiario"] = beneficiaryData.beneficiary;
        data["nascimento"] = beneficiaryData.birthday;
        data["genero"] = beneficiaryData.genre;
        data["altura"] = beneficiaryData.height;
        data["imc"] = beneficiaryData.imc;
        data["classifierImc"] = beneficiaryData.classifierImc;
        data["peso"] = beneficiaryData.weight;
        data["idade"] = beneficiaryData.age;

        beneficiaryData.columns.forEach((column, index) => {
            if(column !== null){
                if(index <= lastId){
                    if(! multipleQuestions.find(i => i === index)){
                        if(Array.isArray(beneficiaryData.answers[index])){
                            data[column] = beneficiaryData.answers[index][0];
                        }
                        else{
                            data[column] = beneficiaryData.answers[index];
                        }
                    }
                    else{
                        const filteredAnswers = beneficiaryData.answers[index].filter(x => x === "Não" || x === "Não sei");
                        if(filteredAnswers.length > 0){                        
                            data[column] = "Não";
                        }
                        else{
                            data[column] = "Sim";
                        }
                    }
                }                
            }            
        })

        return data;
    }

    function datediff(first, second) {
        return Math.round((first-second)/(1000*60*60*24));
    }

    function treatImc(){
        context['beneficiaries'].forEach(beneficiary => {
            for(var j=0; j < imc["normal"].length; j++){                
                beneficiary["classifierImc"] = classifierImc(imc["normal"][j], beneficiary["imc"], "normal");
            }

            if(
                beneficiary["genre"] === "Feminino" && 
                beneficiary["answers"][7] !== null
            )
            {
                for(var i = 0; i < beneficiary.answers[7].length; i++) { 
                    if(beneficiary.answers[7][i] === 'Sim'){
                        let today = new Date();

                        let pregantDate = new Date(beneficiary.answers[8].concat('-28')); 
                        pregantDate.setMonth(pregantDate.getMonth() - 9);

                        let gestationWeek = Math.round(datediff(today, pregantDate) / 7, 2);

                        beneficiary["classifierImc"] = consultingImcTable(imc["pregnant"], gestationWeek.toString(), beneficiary["imc"])
                    }
                }                
            } 
        });
        setStorage(context);   
    }

    function consultingImcTable(imcList, gestationWeek, imc){
        for(var i=0; i < imcList.length; i++){
            if(imcList[i]["Semana Gestacional"] === gestationWeek){
                return classifierImc(imcList[i], imc, "gravida");
            }
        }
    }

    function classifierImc(imcList, imc, type){
        if(type === "gravida"){
            if(imc <= parseFloat(imcList["Baixo Peso"])){
                return "Abaixo do peso"
            }
            else if(imc >= parseFloat(imcList["Adequado - min"]) && imc <= parseFloat(imcList["Adequado - max"])){
                return "Peso adequado"
            }
            else if(imc >= parseFloat(imcList["Sobrepeso - min"]) && imc <= parseFloat(imcList["Sobrepeso - max"])){
                return "Sobrepeso"
            }
            else if(imc >= parseFloat(imcList["Obesidade"])){
                return "Obesidade"
            }
        }else{
            if(imc < parseFloat(imcList["Muito abaixo do peso"])){
                return "Muito abaixo do peso"
            }
            else if(imc >= parseFloat(imcList["Abaixo do peso - min"]) && imc < parseFloat(imcList["Peso adequado - min"])){
                return "Abaixo do peso"
            }
            else if(imc >= parseFloat(imcList["Peso adequado - min"]) && imc < parseFloat(imcList["Sobrepeso - min"])){
                return "Peso adequado"
            }
            else if(imc >= parseFloat(imcList["Sobrepeso - min"]) && imc < parseFloat(imcList["Obesidade I - min"])){
                return "Sobrepeso"
            }
            else if(imc >= parseFloat(imcList["Obesidade I - min"]) && imc < parseFloat(imcList["Obesidade II - min"])){
                return "Obesidade I"
            }
            else if(imc >= parseFloat(imcList["Obesidade II - min"]) && imc < parseFloat(imcList["Obesidade III"])){
                return "Obesidade II"
            }
            else if(imc >= parseFloat(imcList["Obesidade III"])){
                return "Obesidade III"
            }
        }        
    }

    function handleForm(){  
        let emptyAnswerLenght = validateAnsweredQuestions();   
        if(emptyAnswerLenght === 0){
            if(currentScreen === Object.values(context.questions).length){
                setCurrentScreen(currentScreen);   
                treatImc();            
                saveData();                
            } 
            else{
                setCurrentScreen(currentScreen + 1); 
                window.scrollTo(0, 0);
            }
        }    
    }

    return(
        <Container>
            <ToastContainer /> 
            <ProgressBar progress={currentScreen + 1}></ProgressBar>
                {   context.questions[`Tela ${currentScreen}`].map(                        
                            (question, index)=>{
                                return(
                                    <Form key={question.id} questionIndex={index} currentScreen={currentScreen} question={question} />
                                )
                                
                            }
                        )
                }
            <ButtonsGroup>
            {
            showButton ?
            <>
                <RedirectButton pt='VOLTAR' en='BACK' onClick={handleBack} />
                    <RedirectButton pt='AVANÇAR' en='NEXT' onClick={handleForm} />
                <RedirectButton redirect="quit" pt='ABANDONAR' en='QUIT SURVEY' onClick={()=>{}} />
            </>
            :
            <label>Salvando...</label>
            }           
            </ ButtonsGroup>
        </ Container>    
    );
}

export default Question;