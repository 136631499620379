import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Title = styled.h3`
`;

export const CardContainer = styled.div`
  background-color: ${(props) => props.color === 'green' ? '#AFFFD1' :'#FFC2C2'};
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
`;

export const ConclusionContainer = styled.div`
  background-color: #EF9247;
  margin: 5px;
  padding: 20px 30px;
  border-radius: 5px;
  width: 100%;
  min-height: 100px;
`;

export const List = styled.li`
    font-size: 16px;    
    color: #342a2a;
`;


export const Strong = styled.span`
    font-weight: bold;
    color: #342a2a;
    margin-bottom: 10px;
`;

export const Text = styled.span`
    font-size: 16px;    
    color: #342a2a;
`;

export const Image = styled.img`
  width: 15%;
`;

export const CardImage = styled.img`
  width: 170px;
  height: 100px;
`;

export const BeneficiaryGroup = styled.div`
  display: flex;  
`;

export const BeneficiaryButton = styled.button`
  min-width: 100px;
  height: 70px;
  background: ${(props) => props.color === 'T' ? '#7684e5' :'#a4ace9'};
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  border: none;
`;