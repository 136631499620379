import React from 'react';

import { Container, Bar } from './styles';
import { getStorage } from '../../utils/storage';

const ProgressBar = (props) => {
  let context = getStorage();
  
  return(    
    <Container>
        <Bar>
        {
          context?.questions && Object.keys(context.questions).map((key, index, array) => {
            const isLastItem = index === array.length - 1;

            return (
              <li
                key={key}
                className={isLastItem ? (props.progress >= 7 ? 'activated' : '') : (props.progress >= index + 1 ? 'activated' : '')}
              >
                {!isLastItem && <span></span>}
              </li>
            );
          })
        }
        </Bar>
    </Container>
  );
}

export default ProgressBar;