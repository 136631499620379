import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 30px 0;
`;

export const Title = styled.h3`
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  color: #333;
`;

export const Subtitle = styled.h4`
    margin-bottom: 10px;
`;

export const Label = styled.label`
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5%;
`;

export const InputGroup = styled.div`
  margin-bottom: 50px;
`;

export const Note = styled.p`
    font-size: 12px;
    color: #999;
`;

export const Input = styled.input`
    line-height: 25px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    color: #555;
    width: 100%;    
`;
