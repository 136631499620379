import React from 'react';

import { Container } from './styles';

const Erro = () => {
  return(
      <Container>Falha ao acessar a pesquisa</Container>
  );
}

export default Erro;