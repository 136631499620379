export const imc = {
    "normal": [
        {
            "Muito abaixo do peso": "17",
            "Abaixo do peso - min": "17",
            "Abaixo do peso - max": "18.5",
            "Peso adequado - min": "18.5",
            "Peso adequado - max": "25.0",
            "Sobrepeso - min": "25.0",
            "Sobrepeso - max": "30.0",
            "Obesidade I - min": "30.0",
            "Obesidade I - max": "35.0",
            "Obesidade II - min": "35.0",
            "Obesidade II - max": "40.0",
            "Obesidade III": "40.0"  
        }
    ],
    "pregnant": [
        {
            "Semana Gestacional": "6",
            "Baixo Peso": "20.0",
            "Adequado - min": "20.0",
            "Adequado - max": "25.0",
            "Sobrepeso - min": "25.0",
            "Sobrepeso - max": "30.1",
            "Obesidade": "30.1"
        },
        {
            "Semana Gestacional": "7",
            "Baixo Peso": "20.0",
            "Adequado - min": "20.0",
            "Adequado - max": "25.0",
            "Sobrepeso - min": "25.0",
            "Sobrepeso - max": "30.1",
            "Obesidade": "30.1"
        },
        {
            "Semana Gestacional": "8",
            "Baixo Peso": "20.2",
            "Adequado - min": "20.2",
            "Adequado - max": "25.1",
            "Sobrepeso - min": "25.1",
            "Sobrepeso - max": "30.2",
            "Obesidade": "30.2"
        },
        {
            "Semana Gestacional": "9",
            "Baixo Peso": "20.2",
            "Adequado - min": "20.2",
            "Adequado - max": "25.1",
            "Sobrepeso - min": "25.1",
            "Sobrepeso - max": "30.2",
            "Obesidade": "30.2"
        },
        {
            "Semana Gestacional": "10",
            "Baixo Peso": "20.3",
            "Adequado - min": "20.3",
            "Adequado - max": "25.3",
            "Sobrepeso - min": "25.3",
            "Sobrepeso - max": "30.3",
            "Obesidade": "30.3"
        },
        {
            "Semana Gestacional": "11",
            "Baixo Peso": "20.4",
            "Adequado - min": "20.4",
            "Adequado - max": "25.4",
            "Sobrepeso - min": "25.4",
            "Sobrepeso - max": "30.4",
            "Obesidade": "30.4"
        },
        {
            "Semana Gestacional": "12",
            "Baixo Peso": "20.5",
            "Adequado - min": "20.5",
            "Adequado - max": "25.5",
            "Sobrepeso - min": "25.5",
            "Sobrepeso - max": "30.4",
            "Obesidade": "30.4"
        },
        {
            "Semana Gestacional": "13",
            "Baixo Peso": "20.7",
            "Adequado - min": "20.7",
            "Adequado - max": "25.7",
            "Sobrepeso - min": "25.7",
            "Sobrepeso - max": "30.5",
            "Obesidade": "30.5"
        },
        {
            "Semana Gestacional": "14",
            "Baixo Peso": "20.8",
            "Adequado - min": "20.8",
            "Adequado - max": "25.8",
            "Sobrepeso - min": "25.8",
            "Sobrepeso - max": "30.6",
            "Obesidade": "30.6"
        },
        {
            "Semana Gestacional": "15",
            "Baixo Peso": "20.9",
            "Adequado - min": "20.9",
            "Adequado - max": "25.9",
            "Sobrepeso - min": "25.9",
            "Sobrepeso - max": "30.7",
            "Obesidade": "30.7"
        },
        {
            "Semana Gestacional": "16",
            "Baixo Peso": "21.1",
            "Adequado - min": "21.1",
            "Adequado - max": "26.0",
            "Sobrepeso - min": "26.0",
            "Sobrepeso - max": "30.8",
            "Obesidade": "30.8"
        },
        {
            "Semana Gestacional": "17",
            "Baixo Peso": "21.2",
            "Adequado - min": "21.2",
            "Adequado - max": "26.1",
            "Sobrepeso - min": "26.1",
            "Sobrepeso - max": "30.9",
            "Obesidade": "30.9"
        },
        {
            "Semana Gestacional": "18",
            "Baixo Peso": "21.3",
            "Adequado - min": "21.3",
            "Adequado - max": "26.2",
            "Sobrepeso - min": "26.2",
            "Sobrepeso - max": "31.0",
            "Obesidade": "31.0"
        },
        {
            "Semana Gestacional": "19",
            "Baixo Peso": "21.5",
            "Adequado - min": "21.5",
            "Adequado - max": "26.3",
            "Sobrepeso - min": "26.3",
            "Sobrepeso - max": "31.0",
            "Obesidade": "31.0"
        },
        {
            "Semana Gestacional": "20",
            "Baixo Peso": "21.6",
            "Adequado - min": "21.6",
            "Adequado - max": "26.4",
            "Sobrepeso - min": "26.4",
            "Sobrepeso - max": "31.1",
            "Obesidade": "31.1"
        },
        {
            "Semana Gestacional": "21",
            "Baixo Peso": "21.8",
            "Adequado - min": "21.8",
            "Adequado - max": "26.5",
            "Sobrepeso - min": "26.5",
            "Sobrepeso - max": "31.2",
            "Obesidade": "31.2"
        },
        {
            "Semana Gestacional": "22",
            "Baixo Peso": "21.9",
            "Adequado - min": "21.9",
            "Adequado - max": "26.7",
            "Sobrepeso - min": "26.7",
            "Sobrepeso - max": "31.3",
            "Obesidade": "31.3"
        },
        {
            "Semana Gestacional": "23",
            "Baixo Peso": "22.1",
            "Adequado - min": "22.1",
            "Adequado - max": "26.9",
            "Sobrepeso - min": "26.9",
            "Sobrepeso - max": "31.4",
            "Obesidade": "31.4"
        },
        {
            "Semana Gestacional": "24",
            "Baixo Peso": "22.3",
            "Adequado - min": "22.3",
            "Adequado - max": "27.0",
            "Sobrepeso - min": "27.0",
            "Sobrepeso - max": "31.6",
            "Obesidade": "31.6"
        },
        {
            "Semana Gestacional": "25",
            "Baixo Peso": "22.5",
            "Adequado - min": "22.5",
            "Adequado - max": "27.1",
            "Sobrepeso - min": "27.1",
            "Sobrepeso - max": "31.7",
            "Obesidade": "31.7"
        },
        {
            "Semana Gestacional": "26",
            "Baixo Peso": "22.7",
            "Adequado - min": "22.7",
            "Adequado - max": "27.3",
            "Sobrepeso - min": "27.3",
            "Sobrepeso - max": "31.8",
            "Obesidade": "31.8"
        },
        {
            "Semana Gestacional": "27",
            "Baixo Peso": "22.8",
            "Adequado - min": "22.8",
            "Adequado - max": "27.4",
            "Sobrepeso - min": "27.4",
            "Sobrepeso - max": "31.9",
            "Obesidade": "31.9"
        },
        {
            "Semana Gestacional": "28",
            "Baixo Peso": "23.0",
            "Adequado - min": "23.0",
            "Adequado - max": "27.6",
            "Sobrepeso - min": "27.6",
            "Sobrepeso - max": "32.0",
            "Obesidade": "32.0"
        },
        {
            "Semana Gestacional": "29",
            "Baixo Peso": "23.2",
            "Adequado - min": "23.2",
            "Adequado - max": "27.7",
            "Sobrepeso - min": "27.7",
            "Sobrepeso - max": "32.1",
            "Obesidade": "32.1"
        },
        {
            "Semana Gestacional": "30",
            "Baixo Peso": "23.4",
            "Adequado - min": "23.4",
            "Adequado - max": "27.9",
            "Sobrepeso - min": "27.9",
            "Sobrepeso - max": "32.2",
            "Obesidade": "32.2"
        },
        {
            "Semana Gestacional": "31",
            "Baixo Peso": "23.5",
            "Adequado - min": "23.5",
            "Adequado - max": "28.0",
            "Sobrepeso - min": "28.0",
            "Sobrepeso - max": "32.3",
            "Obesidade": "32.3"
        },
        {
            "Semana Gestacional": "32",
            "Baixo Peso": "23.7",
            "Adequado - min": "23.7",
            "Adequado - max": "28.1",
            "Sobrepeso - min": "28.1",
            "Sobrepeso - max": "32.4",
            "Obesidade": "32.4"
        },
        {
            "Semana Gestacional": "33",
            "Baixo Peso": "23.9",
            "Adequado - min": "23.9",
            "Adequado - max": "28.2",
            "Sobrepeso - min": "28.2",
            "Sobrepeso - max": "32.5",
            "Obesidade": "32.5"
        },
        {
            "Semana Gestacional": "34",
            "Baixo Peso": "24.0",
            "Adequado - min": "24.0",
            "Adequado - max": "28.4",
            "Sobrepeso - min": "28.4",
            "Sobrepeso - max": "32.6",
            "Obesidade": "32.6"
        },
        {
            "Semana Gestacional": "35",
            "Baixo Peso": "24.2",
            "Adequado - min": "24.2",
            "Adequado - max": "28.5",
            "Sobrepeso - min": "28.5",
            "Sobrepeso - max": "32.7",
            "Obesidade": "32.7"
        },
        {
            "Semana Gestacional": "36",
            "Baixo Peso": "24.3",
            "Adequado - min": "24.3",
            "Adequado - max": "28.6",
            "Sobrepeso - min": "28.6",
            "Sobrepeso - max": "32.8",
            "Obesidade": "32.8"
        },
        {
            "Semana Gestacional": "37",
            "Baixo Peso": "24.5",
            "Adequado - min": "24.5",
            "Adequado - max": "28.8",
            "Sobrepeso - min": "28.8",
            "Sobrepeso - max": "32.9",
            "Obesidade": "32.9"
        },
        {
            "Semana Gestacional": "38",
            "Baixo Peso": "24.6",
            "Adequado - min": "24.6",
            "Adequado - max": "28.9",
            "Sobrepeso - min": "28.9",
            "Sobrepeso - max": "33.0",
            "Obesidade": "33.0"
        },
        {
            "Semana Gestacional": "39",
            "Baixo Peso": "24.8",
            "Adequado - min": "24.8",
            "Adequado - max": "29.0",
            "Sobrepeso - min": "29.0",
            "Sobrepeso - max": "33.1",
            "Obesidade": "33.1"
        },
        {
            "Semana Gestacional": "40",
            "Baixo Peso": "25.0",
            "Adequado - min": "25.0",
            "Adequado - max": "29.2",
            "Sobrepeso - min": "29.2",
            "Sobrepeso - max": "33.2",
            "Obesidade": "33.2"
        },
        {
            "Semana Gestacional": "41",
            "Baixo Peso": "25.1",
            "Adequado - min": "25.1",
            "Adequado - max": "29.3",
            "Sobrepeso - min": "29.3",
            "Sobrepeso - max": "33.3",
            "Obesidade": "33.3"
        },
        {
            "Semana Gestacional": "42",
            "Baixo Peso": "25.1",
            "Adequado - min": "25.1",
            "Adequado - max": "29.3",
            "Sobrepeso - min": "29.3",
            "Sobrepeso - max": "33.3",
            "Obesidade": "33.3"
        }
    ]
}