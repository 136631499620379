import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';

import { useSearch } from '../../providers/search';

import RedirectButton from '../../components/RedirectButton';
import { Text, PrivacyLink } from './styles';

const Initial = (props) => {
  const { corretor, cliente } = props.match.params;

  const history = useHistory();
  const {search, setSearch} = useSearch();

  function handleNext(){
    setSearch({ ...search, 
      startDate: new Date(), 
      corretor, 
      cliente 
    });

    localStorage.setItem('current_page', 1);
    history.push(`/1`)
  }

  return(
    <Text>
      <br />
      {ReactHtmlParser(localStorage.getItem('texto_inicial'))}
      <br />
      <PrivacyLink href="https://www.suridata.com.br/politica-de-privacidade/" target="_blank">
        Para saber mais sobre o uso dos seus dados, consulte nossa Política de Privacidade completa acessando este link.
      </PrivacyLink>
      <br />
      <br />
      
      <RedirectButton pt='Iniciar' en='Start' onClick={handleNext} />
    </Text>
  );
}

export default Initial;