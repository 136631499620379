import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const ContainerQuestion = styled.div`
    margin: 20px 0;
`;

export const OptionGroup = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
`;

export const OptionContent = styled.div`
    display: flex;
    flex-direction: column;    
    border-bottom: 1px solid #eee;
    width: 100%;   
`;

export const OptionTitle = styled.div`
    background: #222;
    color: #eee;
    height: 30px;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    display: flex;
    justify-content:center;
    align-items:center;
`;

export const OptionRow = styled.div`
    height: 35px;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    display: flex;
    justify-content:left;
    align-items:center;
    padding-left: 4px; 
    @media (max-width: 650px) {
        height: 50px;
        font-size: 10px;
    }
    @media (max-width: 450px) {
        height: 55px;
        font-size: 10px;
    }
    @media (max-width: 250px) {
        height: 60px;
        font-size: 9px;
    } 
`;

export const AnswersContent = styled.div`
    border-left: 1px solid #eee;
    display: flex;
    width: 50%; 
`;

export const Table = styled.table`
    margin: 20px 0;
    border: 1px solid #eee;
    width: 100%;
`;
export const Tr = styled.tr`
    border: 1px solid #eee;
`;
export const Td = styled.td`
    border: 1px solid #eee;
    ${props => {
        if (props.inputType === "text") {
            return `
                padding: 0!important;
            `;
        }
        else{
            return `padding: 15px 0;`;
        }
    }}
    font-size: 12px;
    text-align: center;
`;
export const Thead = styled.thead`
    background: #222;
    color: #eee;
`;
export const Tbody = styled.tbody`
    margin: 50px;
`;

export const Option = styled.input`
    width: 100%;
    cursor: pointer;
    width: 25px;
    
`;

export const DateOption = styled.input`
    width: 100%;
    cursor: pointer;
    width: 105px;
    
`;

export const Label = styled.label`
    font-size: 11px;
`;

export const Title = ({ show, text }) => {
    const StyledTitle = styled.h3`
        width: 100%;
        color: #555;
        font-size: 16px;
        font-weight: 200;
        ${show ? `
            label{
                display: none;
            }
        `:''}
        `
  
    return <StyledTitle>{text}</ StyledTitle>
}

export const Subtitle = styled.h1`
    width: 100%;
    color: #777;
    font-size: 14px;
    font-weight: 200;
    margin-top: 5px;
`;