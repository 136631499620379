import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 3px;
`;

export const ContainerQuestion = styled.div`
    margin: 20px 0;
`;

export const Table = styled.table`
    margin: 20px 0;
    border: 1px solid #eee;
    width: 100%;
`;
export const Tr = styled.tr`
    border: 1px solid #eee;
`;
export const Td = styled.td`
    border: 1px solid #eee;
    padding: 15px 0;
    font-size: 18px;
    text-align: center;
`;
export const Thead = styled.thead`
    background: #222;
    color: #eee;
`;
export const Tbody = styled.tbody`
    margin: 50px;
`;

export const Option = styled.input`
    width: 100%;
    cursor: pointer;
    width: 25px;
`;

export const Label = styled.label`
    font-size: 18px;
`;

export const Title = styled.h1`
    width: 100%;
    color: #555;
    font-size: 14px;
    font-weight: 200;
`;