import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  
`;

export const Text = styled.label`
  font-size: 19px;
  font-weight: 600;
`;

export const Note = styled.p`
    font-size: 12px;
    color: #999;
`;

export const Input = styled.input`
    width: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 10px 5px;
    margin-left: 10px;
`;
