import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSearch } from '../../providers/search';

import { getStorage, setStorage } from '../../utils/storage';
import { nameMask, cpfMask, phoneMask } from '../../utils/mask';

import { Container, Title, Form, InputGroup, Subtitle, Input, Label, Note } from './styles';

import { v4 as uuidv4 } from 'uuid';

const PersonalForm = (props) => {
  let context = getStorage();
  let beneficiaryData = context['beneficiaries'][props.position];
  
  if(beneficiaryData === undefined || beneficiaryData === null){
    beneficiaryData = {
      chave_pessoa: uuidv4(),
      name: '',
      cpf: '',
      phone: '',
      email: '',
      kinship: '',
      genre: '',
      birthday: '',
      age: '',
      weight: '',
      height: '',
      imc: '',
    };
  }

  const [birthday, setBirthday] = useState(beneficiaryData.birthday);
  const [age, setAge] = useState(beneficiaryData.age);
  const [weight, setWeight] = useState(beneficiaryData.weight);
  const [height, setHeight] = useState(beneficiaryData.height);
  const [genre, setGenre] = useState(beneficiaryData.genre);
  
  const imc = beneficiaryData.imc;

  const [name, setName] = useState(beneficiaryData.name);
  const [cpf, setCPF] = useState(beneficiaryData.cpf);
  const [phone, setPhone] = useState(beneficiaryData.phone);
  const [email, setEmail] = useState(beneficiaryData.email);
  const [kinship, setKinship] = useState(beneficiaryData.kinship);
  const {search, setSearch} = useSearch();
  
  const notify = (message) => toast.warn(message);

  let beneficiaries = context.beneficiaries;

  useEffect(()=>{ 
    beneficiaries[props.position] = 
    {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf,
      phone,
      email,
      kinship,
      genre,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }

    context.beneficiaries = beneficiaries;
    setStorage(context);
  });

  function handleName(event){
    let { value } = event.target;

    value = nameMask(event.target.value);

    beneficiaries[props.position] = {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name: value,
      cpf,
      phone,
      email,
      kinship,
      genre,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }    

    setName(value);    
    setSearch({ ...search, beneficiaries });

    context.beneficiaries = beneficiaries;
    setStorage(context); 
  }

  function handleEmail(event){
    let { value } = event.target;

    beneficiaries[props.position] = {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf,
      phone,
      email: value,
      kinship,
      genre,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }    

    setEmail(value);    
    setSearch({ ...search, beneficiaries });

    context.beneficiaries = beneficiaries;
    setStorage(context); 
  }

  function handleCPF(event){
    let { value } = event.target;

    value = cpfMask(event.target.value);

    beneficiaries[props.position] = {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf: value,
      phone,
      email,
      kinship,
      genre,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }    

    setCPF(value);    
    setSearch({ ...search, beneficiaries });

    context.beneficiaries = beneficiaries;
    setStorage(context); 
  }

  function handlePhone(event){
    let { value } = event.target;

    value = phoneMask(event.target.value);

    beneficiaries[props.position] = {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf,
      phone: value,
      email,
      kinship,
      genre,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }    

    setPhone(value);    
    setSearch({ ...search, beneficiaries });

    context.beneficiaries = beneficiaries;
    setStorage(context); 
  }

  function handleGenre(event){
    const { value } = event.target;

    beneficiaries[props.position] = {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf,
      phone,
      email,
      kinship,
      genre: value,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }    

    setGenre(value);    
    setSearch({ ...search, beneficiaries });

    context.beneficiaries = beneficiaries;
    setStorage(context); 
  }

  function handleKinship(event){
    const { value } = event.target;

    beneficiaries[props.position] = {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf,
      phone,
      email,
      kinship: value,
      genre,
      birthday,
      age,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }    

    setKinship(value);    
    setSearch({ ...search, beneficiaries });

    context.beneficiaries = beneficiaries;
    setStorage(context); 
  }

  function handleBirthday(event){
    const { value } = event.target;
    const calculatedAge = calculateAge(value);

    beneficiaries[props.position] = 
    {
      chave_pessoa: beneficiaryData.chave_pessoa,
      beneficiary: props.beneficiary,
      name,
      cpf,
      phone,
      email,
      kinship,
      genre,
      birthday: value,
      age: calculatedAge,
      weight,
      height,
      imc,
      columns: [],
      questions: [],
      answers: []
    }

    if (calculatedAge < 0 || calculatedAge > 150){ 
      notify("Idade inválida")
    }
    else{
      setBirthday(value); 
      setAge(calculatedAge);
      setSearch({ ...search, beneficiaries });

      context.beneficiaries = beneficiaries;
      setStorage(context); 
    }
  }

  function handleWeight(event) {
    let { value } = event.target;
    value = value.replace(',', '.');

    if (value < 0 || value > 400){ 
      notify("Peso inválido")
    }
    else{
      beneficiaries[props.position] = 
      {
        chave_pessoa: beneficiaryData.chave_pessoa,
        beneficiary: props.beneficiary,
        name,
        cpf,
        phone,
        email,
        kinship,
        genre,
        birthday,
        age,
        weight: value,
        height,
        imc: 0,
        columns: [],
        questions: [],
        answers: []
      }

      setWeight(value);
      setSearch({ ...search, beneficiaries });

      context.beneficiaries = beneficiaries;
      setStorage(context); 
    }    
  }

  function handleHeight(event) {
    let { value } = event.target;
    value = heightMask(value);
    value = value.replace(',', '.')

    if (value < 0 || value >= 3.0){ 
      notify("Altura inválida")
    }
    else{
      beneficiaries[props.position] = 
      {
        chave_pessoa: beneficiaryData.chave_pessoa,
        beneficiary: props.beneficiary,
        name,
        cpf,
        phone,
        email,
        kinship,
        genre,
        birthday,
        age,
        weight,
        height: value,
        imc: 0,
        columns: [],
        questions: [],
        answers: []
      }
      setHeight(value);
      setSearch({ ...search, beneficiaries });

      context.beneficiaries = beneficiaries;
      setStorage(context); 
    }
  }

  function heightMask(value) {
    var heightRaw = value.replace(/\D/g, '');
    return heightRaw.replace(/^(\d{1})(\d)/g, '$1.$2');
  }  

  function calculateAge(value){
    var birthYear = value.split('-')[0];

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();

    return currentYear - birthYear;
  }

  return(
    
    <Container>
      <ToastContainer />

      <Title>{props.beneficiary}</Title>
      <Form>
        {(props.beneficiary !== "Titular" && localStorage.getItem('mapeamento_anonimizado') !== '1') &&
        <>
          <InputGroup>
            <Subtitle>Nome completo</Subtitle>
            <Input text="Nome completo" onChange={handleName} value={name} />
          </InputGroup>

          <InputGroup>
            <Subtitle>CPF</Subtitle>
            <Input text="CPF" onChange={handleCPF} value={cpf} />
          </InputGroup>

          <InputGroup>
            <Subtitle>E-mail</Subtitle>
            <Input text="email" onChange={handleEmail} value={email} />
          </InputGroup>

          <InputGroup>
            <Subtitle>Telefone</Subtitle>
            <Input text="Telefone" onChange={handlePhone} value={phone} />
          </InputGroup>
        </>
        }

        <InputGroup>
          <Subtitle>Sexo biológico</Subtitle>
          <Input type="radio" id={`genre_masc_${props.position}`} name={`genre${props.position}`} onChange={handleGenre} value="Masculino" checked={ genre === 'Masculino' ? true : false } />
          <Label htmlFor={`genre_masc_${props.position}`}>Masculino</Label>
          <br />
          <Input type="radio" id={`genre_fem_${props.position}`} name={`genre${props.position}`} onChange={handleGenre} value="Feminino" checked={ genre === 'Feminino' ? true : false } />
          <Label htmlFor={`genre_fem_${props.position}`}>Feminino</Label>
        </InputGroup>

        {props.beneficiary !== "Titular" && 
        <InputGroup>
          <Subtitle>Parentesco</Subtitle>
          <Input type="radio" id={`kinship_masc_${props.position}`} name={`kinship${props.position}`} onChange={handleKinship} value="Cônjuge" checked={ kinship === 'Cônjuge' ? true : false } />
          <Label htmlFor={`kinship_conj_${props.position}`}>Cônjuge</Label>
          <br />
          <Input type="radio" id={`kinship_fem_${props.position}`} name={`kinship${props.position}`} onChange={handleKinship} value="Filhos(as)" checked={ kinship === 'Filhos(as)' ? true : false } />
          <Label htmlFor={`kinship_filh_${props.position}`}>Filhos(as)</Label>
        </InputGroup>
        }

        <InputGroup>
          <Subtitle>Data de nascimento</Subtitle>
          <Input type="date" onBlur={handleBirthday} defaultValue={birthday} placeholder="dd/mm/yyyy" required={true} />
        </InputGroup>

        { localStorage.getItem('somente_titulares') === 'true' && 
          <InputGroup>
            <Note>
                    Nota: Digite seu Peso ou valor aproximado (kg) Coloque valores
                    inteiros (Ex: 78)
                    <br />
                    Caso não saiba o seu peso exato, não tem problema, informe um
                    valor aproximado, ok?
            </Note>
          </InputGroup>
        }

        <InputGroup>
          <Subtitle>Peso(kg)</Subtitle>
          <Input type="number" onChange={handleWeight} defaultValue={weight} required={true} />
        </InputGroup>

        <InputGroup>
          <Subtitle>Altura(m)</Subtitle>
          <Input type="text" onChange={handleHeight} value={height} required={true} />
        </InputGroup>

      </Form>
    </Container>
  );
}

export default PersonalForm;